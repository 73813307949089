import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AiOutlineNumber } from "react-icons/ai";
import { IoTextOutline } from "react-icons/io5";
import { TbWorldHeart } from "react-icons/tb";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { countryOptions } from "../../../../configs/global";
import { useCreatePendingRequestMutation } from "../../../../redux/features/WalletApi";

type Props = {
  wallet: any;
  id: string;
  updatedUser: any;
  refetch: any;
};

export default function WithdrawModal({
  wallet,
  id,
  updatedUser,
  refetch,
}: Props) {
  const [createPending, { data, isLoading, isError, isSuccess, error }] =
    useCreatePendingRequestMutation();
  const { register, handleSubmit, reset } = useForm();

  const userObj = {
    profile: updatedUser?.profileImage,
    firstName: updatedUser?.firstName,
    lastName: updatedUser?.lastName,
    email: updatedUser?.email,
  };

  const handleWithdrawal = handleSubmit(async (data) => {
    const { country, bankName, accountName, swiftCode, accountNumber, amount } =
      data;

    if (
      !data?.country ||
      !data?.bankName ||
      !data?.accountName ||
      !data?.swiftCode ||
      !data?.accountNumber ||
      !data?.amount
    ) {
      return toast.error("Please fill all required fields.");
    }

    if (Number(amount) < 0) {
      return toast.error("You cannot withdraw negative amount.");
    }

    if (Number(amount) === 0) {
      return toast.error("You cannot withdraw zero amount.");
    }

    if (Number(amount) < 40) {
      return toast.error("You cannot withdraw less than $40.");
    }

    const walletAmount = updatedUser?.balance;
    console.log(walletAmount);

    if (amount > walletAmount) {
      return toast.error("You cannot withdraw more than your wallet amount.");
    }

    const formData = {
      country,
      bankName,
      accountName,
      swiftCode,
      accountNumber,
      amount,
      userId: id,
      user: userObj,
    };
    await createPending(formData);
  });

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: (data as any).message,
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
      refetch();
    }
  }, [data, isLoading, isError, isSuccess, error, reset, refetch]);

  return (
    <form onSubmit={handleWithdrawal}>
      <input type="checkbox" id="withdraw-modal" className="modal-toggle" />
      <div className={`modal modal-bottom sm:modal-middle`}>
        <div className="modal-box md:w-5/6 lg:w-2/3 md:max-w-5xl">
          <h3 className="font-bold text-xl">Withdrawal Request</h3>
          <p>
            Make sure to update your payment source before requesting
            withdrawal.
          </p>
          {wallet?.pending?.length > 0 ? (
            <div className="py-20 rounded-md bg-gray-200 mt-5">
              <h1 className="text-center flex justify-center items-center px-4">
                Please wait for the old withdrawal request to be approved first.
              </h1>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-x-6">
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold flex items-center gap-1">
                      Country <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">
                        <TbWorldHeart />
                      </div>
                      <select
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        {...register("country")}
                      >
                        {countryOptions.map((country, index) => (
                          <option
                            value={country.value}
                            selected={country?.selected}
                            key={index}
                          >
                            {country.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold">
                      Bank Name <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">
                        <IoTextOutline />
                      </div>
                      <input
                        type="text"
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        placeholder="Enter Bank Name"
                        {...register("bankName")}
                      />
                    </div>
                  </div>
                </div>
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold">
                      Account Name <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">
                        <IoTextOutline />
                      </div>
                      <input
                        type="text"
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        placeholder="Enter Account Name"
                        {...register("accountName")}
                      />
                    </div>
                  </div>
                </div>
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold">
                      Swift Code <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">
                        <AiOutlineNumber />
                      </div>
                      <input
                        type="text"
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        placeholder="Enter Swift Code"
                        {...register("swiftCode")}
                      />
                    </div>
                  </div>
                </div>
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold">
                      Account Number <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">
                        <AiOutlineNumber />
                      </div>
                      <input
                        type="number"
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        placeholder="Enter Account Number"
                        {...register("accountNumber")}
                      />
                    </div>
                  </div>
                </div>
                <div className="name border rounded p-3 relative mt-10 flex-1">
                  <div className="name-title absolute -top-5 bg-white border rounded p-1">
                    <h3 className="text-sm font-bold">
                      Amount <span className="text-red-600">*</span>
                    </h3>
                  </div>
                  <div className="flex items-center gap-3">
                    <div
                      className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                    >
                      <div className="icon">$</div>
                      <input
                        type="number"
                        className={`form-control outline-none pl-4 w-full text-sm`}
                        placeholder="Enter Amount"
                        {...register("amount")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='mt-5'>
                                                                                          <p className='text-sm font-bold'>Note: </p>
                                                                                          <p className='text-sm'>You will get ({profitPercentage}%): <span className='font-bold'>$ {userProfit}</span></p>
                                                                                          <p className='text-sm'>Company will get ({100 - profitPercentage}%): <span className='font-bold'>$ {companyProfit}</span></p>
                                                                                </div> */}
            </>
          )}

          <div className="modal-action">
            <label
              htmlFor="withdraw-modal"
              className="btn btn-sm md:btn-md btn-warning text-white"
            >
              Close
            </label>
            {wallet?.pending?.length > 0 ? (
              <></>
            ) : (
              <>
                {isLoading ? (
                  <button
                    className="btn btn-sm md:btn-md btn-success"
                    type="button"
                  >
                    <PulseLoader size={8} color="#fff" />
                  </button>
                ) : (
                  <button
                    className="btn btn-sm md:btn-md btn-success text-white"
                    type="submit"
                  >
                    Send
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
