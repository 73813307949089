import { HiOutlineClock } from "react-icons/hi";
import { Link } from "react-router-dom";
import Banner from "../../../assets/dash-music.jpg";
import WalletBanner from "../../../assets/wallet.jpg";
import GlobalLoader from "../../../components/GlobalLoader";
import useScrollToTop from "../../../hooks/useScrollToTop";
import useTitle from "../../../hooks/useTitle";
import { authUserInterface } from "../../../interfaces/UserInterface";
import { useGetAllReleasesByArtistQuery } from "../../../redux/features/SongApi";
import { useGetWalletByUserIdQuery } from "../../../redux/features/WalletApi";

const Fade = require("react-reveal/Fade");

type Props = {
  updatedUser: authUserInterface | any;
};

export default function UserDashboard({ updatedUser }: Props) {
  useTitle("Dashboard");
  useScrollToTop();

  const { data, isLoading } = useGetAllReleasesByArtistQuery({
    id: updatedUser?._id,
    status: "pending",
  });

  const pendingSongs = data?.data;

  const { data: walletData, isLoading: walletIsLoading } =
    useGetWalletByUserIdQuery({ userId: updatedUser?._id as string });

  const wallets = walletData?.data?.pending;

  if (isLoading || walletIsLoading) {
    return <GlobalLoader />;
  }

  return (
    <div className="py-0 pb-5 md:pb-5">
      <div className="mt-3 relative">
        <img
          src={Banner}
          alt="Mirror-LTD Music"
          draggable={false}
          className="select-none w-full h-60 md:h-96 lg:h-[25rem] object-cover rounded-xl"
        />
        <div className="absolute bottom-5 md:bottom-10 left-5 md:left-10">
          <h1 className="text-lg md:text-2xl mb-2 font-bold text-white pr-5 md:pr-0">
            Welcome back{" "}
            <Link to={"/profile"}>
              {updatedUser?.firstName + " " + updatedUser?.lastName}
            </Link>
            , Ready for your next track release?
          </h1>
          <p className="font-semibold mb-3 text-sm md:text-[16px] md:mb-5 text-white">
            Create and manage releases
          </p>
          <div className="flex items-center gap-4">
            <Link
              to={"/new-release"}
              className="btn btn-xs md:btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white"
            >
              New Release
            </Link>
            <Link
              to={"/releases"}
              className="btn btn-xs md:btn-sm border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white"
            >
              My Releases
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-5 md:mt-10">
        <h1 className="font-bold text-lg md:text-xl flex items-center gap-2 text-black">
          <HiOutlineClock />
          Pending releases
        </h1>
        <Fade top distance="20px">
          {pendingSongs?.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-3">
              {pendingSongs?.slice(0, 4)?.map((song: any) => (
                <Link
                  to={`/releases/${song._id}`}
                  key={song._id}
                  className={`relative w-full rounded-xl shadow-md flex flex-col group`}
                >
                  <div className="w-full overflow-hidden rounded-xl glass">
                    <img
                      className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                      src={song?.albumCover}
                      alt={song?.releaseTitle}
                      loading="lazy"
                    />
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                  <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                    <h3 className="hidden md:flex text-base md:text-lg font-bold">
                      {song?.releaseTitle?.length > 25
                        ? song?.releaseTitle?.slice(0, 25) + "..."
                        : song?.releaseTitle}
                    </h3>
                    <h3 className="md:hidden text-base md:text-lg font-bold">
                      {song?.releaseTitle?.length > 12
                        ? song?.releaseTitle?.slice(0, 12) + "..."
                        : song?.releaseTitle}
                    </h3>
                    <p className="uppercase text-[10px] md:text-sm font-semibold">
                      {song?.displayArtist[0]?.artistName}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div>
              <h1 className="text-center text-sm mt-5">
                No pending releases found!
              </h1>
              <div className="flex justify-center mt-3">
                <Link
                  to={"/new-release"}
                  className="btn btn-xs md:btn-sm bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white"
                >
                  New Release
                </Link>
              </div>
            </div>
          )}
        </Fade>
      </div>
      <div className="mt-5 md:mt-10">
        <h1 className="font-bold text-lg md:text-xl flex items-center gap-2 text-black">
          <HiOutlineClock />
          Pending Withdrawal
        </h1>
        <Fade top distance="20px">
          {wallets?.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-3">
              {wallets?.slice(0, 3)?.map((wallet: any) => (
                <Link
                  to={`/all-artists/${wallet.userId}`}
                  key={wallet._id}
                  className={`relative w-full rounded-xl shadow-md flex flex-col group`}
                >
                  <div className="w-full overflow-hidden rounded-xl glass">
                    <img
                      className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                      src={WalletBanner}
                      alt={wallet?._id}
                      loading="lazy"
                    />
                  </div>
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                  <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                    <h3 className="text-xl md:text-2xl font-bold">
                      $ {wallet?.amount}
                    </h3>
                    <p className="hidden md:flex text-[10px] md:text-sm font-semibold">
                      {updatedUser?.email}
                    </p>
                    <p className="md:hidden text-[10px] md:text-sm font-semibold">
                      {updatedUser?.email?.length > 25
                        ? updatedUser?.email?.slice(0, 25) + "..."
                        : updatedUser?.email}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div>
              <h1 className="text-center text-sm mt-5">
                No pending withdrawals found!
              </h1>
              <div className="flex justify-center mt-3">
                <Link
                  to={"/wallet"}
                  className="btn btn-xs md:btn-sm bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-full text-white"
                >
                  Wallet
                </Link>
              </div>
            </div>
          )}
        </Fade>
      </div>
    </div>
  );
}
