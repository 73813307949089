import { ScaleLoader } from "react-spinners";

const HomeLoader = () => {
  return (
    <div className="text-center h-screen flex justify-center items-center">
      <ScaleLoader color={"#504FF9"} />
    </div>
  );
};

export default HomeLoader;
