import React from "react";
import { Link } from "react-router-dom";
import GlobalLoader from "../../../../components/GlobalLoader";

type Props = {
  user: any;
  index: number;
  isLoading: boolean;
};

export default function UsersCard({ user, isLoading }: Props) {
  const fullName = `${user?.firstName} ${user?.lastName}`;

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Link
      to={`/all-artists/${user._id}`}
      key={user._id}
      className={`relative w-full rounded-xl shadow-md flex flex-col group`}
    >
      <div className="w-full overflow-hidden rounded-xl glass">
        <img
          className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
          src={user?.profileImage}
          alt={user?.firstName}
          loading="lazy"
        />
        <div className="absolute top-2 right-2">
          <span
            className={`badge badge-sm md:badge-md text-white ${user?.isOnline ? "badge-success" : "badge-neutral"} opacity-100 transition-opacity duration-300 ease-in-out`}
          >
            {user?.isOnline ? "Online" : "Offline"}
          </span>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
      <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
        <h3 className="text-base md:text-lg font-bold hidden md:block">
          {fullName}
        </h3>
        <h3 className="text-base md:text-lg font-bold md:hidden">
          {fullName?.length > 10 ? fullName?.slice(0, 10) + "..." : fullName}
        </h3>
        <p className="text-xs md:text-sm font-semibold hidden md:block">
          {user?.email}
        </p>
        <p className="text-xs md:text-sm font-semibold md:hidden">
          {user?.email?.length > 15
            ? user?.email?.slice(0, 15) + "..."
            : user?.email}
        </p>
      </div>
    </Link>
  );
}
