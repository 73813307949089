import moment from "moment";
import useScrollToTop from "../../../../hooks/useScrollToTop";

export default function Agreement() {
  useScrollToTop();

  return (
    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
      <div className="title my-2 mb-10">
        <h3 className="text-2xl font-bold">Agreement and Terms of Use</h3>
        <span>
          Last updated: {moment("2024-01-10").format("MMMM Do, YYYY")}
        </span>
      </div>
      <div className="space-y-5">
        <div className="space-y-1">
          <h1 className="font-bold text-lg">Terms of Use</h1>
          <p>
            We have updated the Terms of Use, Privacy Policy and our Anti-Fraud
            Policy. We encourage you to read them carefully.
          </p>
          <p>
            Thank you very much for choosing MIRROR-LTD MUSIC as your Digital
            Music Distributor. We hope you enjoy our service and achieve great
            success with your music career. Please, read these Terms of Use very
            carefully as they constitute a binding agreement between you.
          </p>
          <p>
            Formally, the provider of the service and responsible of the
            Platform is Mirror-LTD Music, which is a limited liability Company
            constituted and existing under the laws of United State, with legal
            address 314 N Rusk Ave, Denison, Texas in Tax ID # and registered at
            the Commercial Register of Texas with Company Registration Number.
          </p>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Definitions</h1>
          <p>
            To facilitate the understanding of these Terms of Use, the following
            principal expressions will have these meanings:
          </p>
          <ul className="space-y-1 list-disc pl-5 pt-2">
            <li>
              “Customer”: refers to any individual that accesses or makes use of
              a Digital Music Service.
            </li>
            <li>
              “Digital Distribution”: means the transferring by any means of
              data transmission or communication, through the internet, internet
              radio, kiosks, in-store listening posts, mobile, wireless,
              satellite and similar communication systems, whether now known or
              existing in the future, of the End User Content in multiple
              digital formats including but not limited to clips, permanent
              downloads, subscriptions, streams and timeout-downloads,
              ring-tones and ring-back tones and any other means.
            </li>
            <li>
              “Digital Music Service”: means any digital outlet, such as music
              download portals, music and video streaming services, mobile music
              platforms, digital (and terrestrial) radio stations, digital (and
              terrestrial) television networks, and mobile networks (each a
              “DMS”, i.e.: Apple iTunes, Spotify, Tidal, Google Play, etc.),
              that enables Customers to purchase and/or listen to End User
              Content.
            </li>
            <li>
              “End User”: that’s You (hereinafter, the End User), which is an
              artist, songwriter, author, producer, agent (including royalty
              recipients), rights holder or others who are authorized and
              entitled to exploit certain music (including the composition and
              the recording) and to use the Platform, the Platform API or
              portions thereof.
            </li>
            <li>
              “End User Content”: means all intellectual property works
              (including without limitation musical works, recordings, video
              clips, ring-tones, real-tones, lyrics, logos, covers and photos)
              as to which the End User has the necessary exploitation rights,
              including “Neigboring Rights”.
            </li>
            <li>
              “Service”: means the service provided by Us through the Platform,
              in order to make the End User Content available on Digital Music
              Services (here, the Digital Distribution Services).
            </li>
          </ul>
          <p>Hence, these are the rights and obligations of each of us:</p>
          <p>What You can do and what you can't do</p>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Use of the Platform</h1>
          <p>
            By registering and uploading Your Content on the Platform, You
            assume and undertake, essentially, the following obligations:
          </p>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              You shall use the Platform diligently and upload information and
              content whose rights belong to You or for which You are authorized
              by the rights holder.
            </li>
            <li>
              You shall provide all the necessary information to use the
              Service, which We will request during the use of the Service.
            </li>
            <li>
              You shall pay all the applicable fees for the Services rendered by
              Us, as described below.
            </li>
            <li>
              You shall inform Us of any activity that is inconsistent with this
              Terms of Use.
            </li>
            <li>
              You shall not perform illegal activities through the Platform or
              the Services, and/or actions that could harm or damage any party,
              including Us.
            </li>
            <li>
              You undertake to use diligently the Platform and, therefore,
              undertake:
            </li>
            <li>
              Not to grant access to the Platform to any third party or to
              employees that due to their position in the company, reasonably
              should not access the Platform;
            </li>
            <li>Not to access the source code of the Platform;</li>
            <li>
              Not to use the information, rules or instructions contained in the
              Platform for purposes different than those established in these
              Terms of Use;
            </li>
            <li>
              Not to disclose to any third party any of the information obtained
              through the Platform;
            </li>
            <li>
              Not to permit the public to access or use the Platform (including
              without limitation, via the internet).
            </li>
            <li>
              Not to use the Platform to upload content not owned by You or for
              which You do not have an explicit license to commercially exploit.
            </li>
            <li>
              Not to reproduce the Platform, in whole or in part, for any
              purposes;
            </li>
            <li>
              Not to copy and/or distribute the Platform, in whole or in part,
              by any manner;
            </li>
            <li>Not to transfer the Platform to any third party;</li>
            <li>
              Not to assign, sell, resell, rent, lease, lend, sublicense,
              outsource or otherwise transfer the Platform and/or the Service to
              any third party, or authorize or appoint any third party to do so;
            </li>
            <li>
              Not to modify the Platform or provide any person with the means to
              do the same. This includes the creation of derivative works and to
              translate, disassemble, recompile, alter, destroy or reverse
              engineer the Platform or attempt to do so, unless when expressly
              permitted by the applicable regulation;
            </li>
            <li>
              Not to circumvent the technological protection measures
              incorporated in the Platform.
            </li>
          </ul>
          <p>
            In general, You agree to use the Platform in a lawful and diligent
            manner and will not do anything forbidden by Law of by these Terms
            of Use. You will be liable to Us in respect of any breach of these
            Terms of Use, as described in Section 9.
          </p>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">
            Upload and storage of Your Content
          </h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              After registration, You can upload Your Content (including sound
              recordings and audiovisual works, photographs, images, and other
              related content) to your personal account, for their subsequent
              Digital Distribution.
            </li>
            <li>
              You can only upload content to the Platform for which You are the
              owner or have the rightsholders’ permission in writing, and cannot
              upload any content whose rights are held by third parties. We may
              ask you to facilitate to Us all documents, contracts and
              registration certificates necessary to confirm that you own the
              rights of Your Content and reserve the right to ask you not to
              upload content from a specific author or producer, or We may also
              remove any of Your Content from the Platform for which We don’t
              have the legal conviction that it belongs to You.
            </li>
            <li>
              As specified before, You can’t, under any circumstance, upload any
              content that could be harmful, threatening, unlawful,
              confidential, defamatory, libelous, harassing, obscene, indecent,
              fraudulent, infringing the rights of privacy, incites hate or
              includes texts of racist, ethnic or other nature, that is against
              or hinders or limits in any way any individual, or which may
              expose Us or third parties to any harm or liability of any kind.
            </li>
            <li>
              You can’t either upload any private or fake information of any
              third party, including, among others, mail addresses, phone
              numbers, and email addresses.
            </li>
            <li>
              You are not allowed to upload any content that may breach
              copyright law or third party brand ownership
            </li>
            <li>
              As We specified before, You are free to exploit Your Content,
              directly or through third parties, to Digital Music Services which
              are not selected or made available on the Platform.
            </li>
            <li>
              We reserve the right to access to and analyze all or part of Your
              Content in order to guarantee the compliance with the Law and with
              these Terms of Use. We also reserve the right to delete files,
              data or information uploaded by You if We deem that they are not
              in compliance with these Terms of Use, or if We think they are not
              suitable or appropriate for the Platform or the Service.
            </li>
          </ul>
          <p>
            Finally, for clarification purposes, these Terms of Use do not
            provide any obligation to You to upload a minimum quantity of
            content and/or a minimum availability of it.
          </p>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Fees</h1>
          <div className="space-y-1">
            <p>
              Additionally, You will receive 85.00% of the net incomes
              (deducting expenses and taxes) which We receive from Digital Music
              Services from the exploitation of Your Content. If applicable, You
              authorize Us to deduct 15% sales commission percentage from the
              net incomes received by Us from Digital Music Services. which can
              be found at any time in the "Wallet" section of your Account.
            </p>
            <p>
              Will be payable via bank-to-bank to the account designated by You.
              If any authority imposes a duty, tax, levy, or fee, You agree to
              pay that amount or supply exemption documentation.
            </p>
            <p>
              Payment of generated sales fees under these Terms of Use shall be
              made on a once a month, within days from receipt of an out payment
              request from you, provided always that the due amount exceeds the
              corresponding minimum payment threshold for the relevant requested
              payout. Nonetheless, You authorize Us to withhold any payment
              during an additional period of twenty-four (24) months in the
              event we deem that such payment contains incomes or fees totally
              or partially generated fraudulently or contravening these Terms of
              Use or the Anti-Fraud Policy. Any payment You receive from Us will
              be subject to all and any applicable taxes (including VAT,
              withholding taxes, etc.).
            </p>
            <p>
              If any Digital Music Service deducts any amount due to any passed
              contingency, overpayment or conclusion in relation to Your Content
              or an investigation by Us reasonably demonstrates that any of Your
              fees for any prior month should have been lesser, We may, at the
              conclusion of such investigation and at our sole discretion,
              provide a revised sales report for the applicable month(s) and
              deduct the corresponding amount from future payments, what You
              acknowledge and accept.
            </p>
            <p>
              Therefore, You expressly and irrevocably authorize Us to collect
              all incomes from the exploitation of Your Content through the
              Platform, including but not limited to author rights, performing
              and recording rights, any levy established by law for private
              copies, or for any other concept, without limitation. For this
              purpose, We may ask you to sign a specific authorization letter as
              solicited by the corresponding Performing Right Organization,
              which You undertake to provide as soon as requested by Us.
            </p>
            <p>
              We reserve the right to change in the future the Service price,
              the sales commission percentage or the minimum payment threshold.
            </p>
            <p>
              We may decide not to charge you initially for the use of the
              Service and any optional service, however, You authorize Us to
              deduct the corresponding amounts from your future payments.
            </p>
            <p>
              In the event that after one year from the start of the
              relationship, You have distributed Your Content on credit, without
              having generated enough sales to pay back the outstanding balance,
              We reserve the right to request the payment of the outstanding
              balance.
            </p>
          </div>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Duration and Termination</h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              We reserve the right to take 1 month to approve a song and release
              a copyright claim.
            </li>
            <li>
              We reserve the right to take 1 month to reply to your message.
              (whatsapp and email.)
            </li>
            <li>
              Additionally, We may terminate our relationship and the Service:
            </li>
            <li>
              in the event You breach any term or condition established by Us
              (here or in any other document accepted by You) and You fail to
              remedy such breach within two (2) days of the date of notice from
              Us;
            </li>
            <li>
              in case the outstanding balance is not paid as per Section 4.7, We
              will have the right to terminate the relationship and cease the
              Service.
            </li>
            <li>
              If You become the subject of any proceeding related to your
              liquidation or insolvency (whether voluntary or involuntary) which
              is not dismissed within sixty (60) calendar days;
            </li>
            <li>If You infringe our Intellectual Property Rights.</li>
            <li>If You infringe our Anti-Fraud Policy.</li>
            <li>
              In case you commit any unlawful activity using the Platform or the
              Service.
            </li>
            <li>
              In all cases, all costs due for any Service provided by Us until
              the termination date, must be duly paid by You.
            </li>
            <li>
              We will not be liable to You for damages of any kind because of
              the termination of our relationship in accordance with these Terms
              of Use. Our respective rights and obligations contained in
              sections that by their nature are intended to survive, will
              survive the termination of this relationship.
            </li>
            <li>
              Regardless the termination of the Service, You and We agree to
              maintain in force those contracts signed by Us with third parties
              before receiving the notification of termination in the event the
              contracts with such third parties would be still in force.
            </li>
          </ul>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Intellectual Property Rights</h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              Nothing contained herein shall be construed as granting or
              conferring any property rights in the Platform or any part thereof
              to You; therefore, We are not granting to You by means of this
              Terms of Use, the right to exploit our Intellectual Property
              (including but not limited to copyright, patent, trademarks,
              registered marks, trade secrets, and confidential and proprietary
              information relating thereto). All these rights are expressly
              reserved by Us and, as a consequence, We will retain all licensed
              or ownership rights to the Platform, our brands, technology, etc.,
              together with any complete or partial copies thereof.
            </li>
            <li>
              When You upload any of Your Content to our servers through the
              Platform, you are recognizing the following: that We are
              authorized to administrate, directly or through third parties,
              Your Content (including the recordings, videos, compositions,
              artwork, etc.) through the Digital Music Services selected by You,
              in the entire world and during the duration of our relationship
              (including section 5.4); that You own and/or control all rights in
              and to the Your Content and/or have the full right and ability to
              upload Your Content and exploit it in the terms described herein;
              that Your Content does not infringe the copyrights or any other
              right, of any third party. that We are authorized during the
              Duration of the agreement, to grant to third parties
              synchronization licenses of Your Content for the entire world.{" "}
            </li>
            <li>
              If any of Your Content use any kind of the so-called “copyleft
              license” and such content was created or developed by a person
              (including artists and producers) which is not associated to any
              Performing Right Organization (such as but not limited to SACEM in
              France, MCPS in UK, SGAE in Spain, GEMA in Germany, etc.) in any
              country of the world, upon the compliance of section 4.4 above,
              then You authorize Us to claim on their behalf, where appropriate,
              to the Performing Right Organization of each country, any
              royalties, levies, duties, etc. that Digital Music Services have
              paid in respect with such content.
            </li>
          </ul>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Fraud</h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              We work very hard and invest extensive resources to avoid
              automated and fraudulent behaviors. For this reason, we have
              created a specific Anti-Fraud Policy, that is available in the
              "Legal" section of your account. When you accept these Terms of
              Use, you also acknowledge and accept our Anti-Fraud Policy and,
              therefore, You accept that, among other commitments, You will not,
              and will not authorize any third party to, directly or indirectly,
              generate automated, fraudulent, or otherwise invalid playback
              actions, especially in Digital Music Services.
            </li>
            <li>
              In this Anti-Fraud Policy we have implemented a account block
              policy; therefore, please, read carefully such policy as We will
              be very strict applying it.
            </li>
          </ul>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">Privacy</h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              Our data protection policy is described in the Privacy Policy. The
              Privacy Policy is part of our relationship and, therefore, when
              you accept these Terms of Use, you are also acknowledging and
              accepting our Privacy Policy.
            </li>
          </ul>
        </div>

        <div className="space-y-1">
          <h1 className="font-bold text-lg">
            Warranty. Limitation of Liability
          </h1>
          <ul className="space-y-1 list-disc pl-5">
            <li>
              We cannot warrant to You that the Platform and the Service will
              meet your requirements. Except as expressly provided in these
              Terms of Use, We provide the Services and the Platform "as is" and
              without warranty. We disclaim all other warranties, express or
              implied, including the implied warranties of non-infringement,
              merchantability, and fitness for a particular purpose. The
              Platform cannot be tested in every possible operating environment,
              therefore We do not warrant that the functions contained in the
              Platform will meet your requirements, that operation of the
              Platform will be uninterrupted, or that the Platform is error
              free. Except as set forth herein and to the extent permitted by
              law, all other warranties, expressed or implied, statutory or
              otherwise, including, but not limited to, implied warranties of
              merchantability, quality, and fitness for a particular purpose are
              excluded on the part of Us. Neither Us nor any of our third-party
              suppliers or partners shall be liable for any injury, loss or
              damage, whether indirect, special, incidental or consequential nor
              for any lost profits, contracts, loss of data or programs, the
              cost of recovering such data, or incorrect, defective or faulty
              performance of Your Content.
            </li>
            <li>
              You will assume all liability and defend, indemnify, and hold Us
              and any party, harmless for the use of the Platform and the
              Service.
            </li>
            <li>
              Our liability under or in connection with the Platform and the
              Service (including damages) whether arising from negligence,
              breach of contract or otherwise shall be limited to the value of
              the fees paid by You to Us during the 12 months prior to the
              claim.
            </li>
            <li>
              We shall not be liable for any loss of, whether arising directly
              or indirectly, (a) profits, (b) savings, (c) goodwill, (d)
              reputation, (e) revenue, (f) anticipated savings, (g) business or
              opportunity or (h) any other like pure economic loss; nor any
              special, indirect, consequential or incidental losses or damages
              of any kind or nature whatsoever regardless of whether in each
              case arising from breach of contract, warranty, tort, strict
              liability, negligence or otherwise, even if advised of the
              possibility of such loss or damage, or if such loss or damage
              could have been reasonably foreseen.
            </li>
            <li>
              We respect the rights of others (including copyright, image and
              personality rights, etc.) and expect our clients to do the same.
              In compliance with the European Directive on Liability of Internet
              Service Providers, we will respond expeditiously to remove or
              disable access to material uploaded by users of the Platform
              and/or the Service that is claimed to infringe third parties’
              rights.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
