import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Avatar from "../../../../assets/avatar.jpg";

type Props = {
  user: any;
  index: number;
};

export default function DetailsCard({ user }: Props) {
  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <Link
      to={`/applications/${user?._id}`}
      key={user._id}
      className={`relative w-full rounded-xl shadow-md flex flex-col group`}
    >
      <div className="w-full overflow-hidden rounded-xl glass">
        <img
          className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
          src={Avatar}
          alt={user?._id}
          loading="lazy"
        />
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
      <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
        <h3 className="text-base md:text-lg font-bold hidden md:block">
          {fullName}
        </h3>
        <h3 className="text-base md:text-lg font-bold md:hidden">
          {fullName?.length > 10 ? fullName?.slice(0, 10) + "..." : fullName}
        </h3>
        <p className="uppercase text-xs md:text-sm font-semibold">
          {user?.artistName}
        </p>
        <p className="text-xs hidden md:block">
          {moment(user?.createdAt).format("MMM Do YYYY, h:mm:ss A")}
        </p>
        <p className="text-[10px] md:hidden">
          {moment(user?.createdAt).format("MMM Do YYYY, h:mm:ss A")}
        </p>
      </div>
    </Link>
  );
}
