import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { GoNumber } from "react-icons/go";
import { IoTextOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useAddAnAdminMutation } from "../../../../redux/features/auth/AuthApi";

export default function AdminModal() {
  const [addAnAdmin, { data, isLoading, isError, isSuccess, error }] =
    useAddAnAdminMutation();
  const { register, handleSubmit, reset } = useForm();

  const handleAddAdmin = handleSubmit(async (data) => {
    const { firstName, lastName, email, phoneNumber } = data;

    if (!firstName || !lastName || !email || !phoneNumber) {
      return toast.error("All fields are required");
    }

    if (phoneNumber?.length < 11 || phoneNumber?.length > 11) {
      return toast.error("Phone number must be 11 digits");
    }

    await addAnAdmin({
      firstName,
      lastName,
      email,
      phoneNumber,
    });
  });

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: (data as any).message,
        showConfirmButton: false,
        timer: 2000,
      });
      reset();
    }

    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: (error as any)?.data?.message,
      });
    }
  }, [data, isLoading, isError, isSuccess, error, reset]);

  return (
    <form onSubmit={handleAddAdmin}>
      <input type="checkbox" id="admin-modal" className="modal-toggle" />
      <div className={`modal modal-bottom sm:modal-middle`}>
        <div className="modal-box md:w-2/3 lg:w-1/3 md:max-w-5xl">
          <h3 className="font-bold text-xl">Add New Admin</h3>
          <p>
            Enter some information of the person you want to add as an admin.
            They will receive an email with instructions on how to accept the
            invitation.
          </p>

          <div>
            <div className="name border rounded p-3 relative mt-10 flex-1">
              <div className="name-title absolute -top-5 bg-white border rounded p-1">
                <h3 className="text-sm font-bold">
                  Email <span className="text-red-600">*</span>
                </h3>
              </div>
              <div className="flex items-center gap-3">
                <div
                  className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                >
                  <div className="icon">
                    <MdOutlineAlternateEmail />
                  </div>
                  <input
                    type="email"
                    className={`form-control outline-none pl-4 w-full text-sm`}
                    placeholder="Enter User's Email Address"
                    {...register("email")}
                  />
                </div>
              </div>
            </div>
            <div className="name border rounded p-3 relative mt-10 flex-1">
              <div className="name-title absolute -top-5 bg-white border rounded p-1">
                <h3 className="text-sm font-bold">
                  First Name <span className="text-red-600">*</span>
                </h3>
              </div>
              <div className="flex items-center gap-3">
                <div
                  className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                >
                  <div className="icon">
                    <IoTextOutline />
                  </div>
                  <input
                    type="text"
                    className={`form-control outline-none pl-4 w-full text-sm`}
                    placeholder="Enter User's First Name"
                    {...register("firstName")}
                  />
                </div>
              </div>
            </div>
            <div className="name border rounded p-3 relative mt-10 flex-1">
              <div className="name-title absolute -top-5 bg-white border rounded p-1">
                <h3 className="text-sm font-bold">
                  Last Name <span className="text-red-600">*</span>
                </h3>
              </div>
              <div className="flex items-center gap-3">
                <div
                  className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                >
                  <div className="icon">
                    <IoTextOutline />
                  </div>
                  <input
                    type="text"
                    className={`form-control outline-none pl-4 w-full text-sm`}
                    placeholder="Enter User's Last Name"
                    {...register("lastName")}
                  />
                </div>
              </div>
            </div>
            <div className="name border rounded p-3 relative mt-10 flex-1">
              <div className="name-title absolute -top-5 bg-white border rounded p-1">
                <h3 className="text-sm font-bold">
                  Phone Number <span className="text-red-600">*</span>
                </h3>
              </div>
              <div className="flex items-center gap-3">
                <div
                  className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
                >
                  <div className="icon">
                    <GoNumber />
                  </div>
                  <input
                    type="number"
                    className={`form-control outline-none pl-4 w-full text-sm`}
                    placeholder="Enter User's Phone Number"
                    {...register("phoneNumber")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal-action">
            <label
              htmlFor="admin-modal"
              className="btn btn-sm md:btn-md btn-warning text-white"
            >
              Close
            </label>
            {isLoading ? (
              <button
                className="btn btn-sm md:btn-md btn-success"
                type="button"
              >
                <PulseLoader size={8} color="#fff" />
              </button>
            ) : (
              <button
                className="btn btn-sm md:btn-md btn-success text-white"
                type="submit"
              >
                Add
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
