import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaClock } from "react-icons/fa";
import { FaClockRotateLeft } from "react-icons/fa6";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import Swal from "sweetalert2";
import GlobalLoader from "../../../../components/GlobalLoader";
import { formateDate } from "../../../../configs/global";
import { useUpdatePendingRequestMutation } from "../../../../redux/features/WalletApi";

type Props = {
  wallet: any;
  userId: string;
  walletLoading: boolean;
  refetch: () => void;
};

export default function WalletHistory({
  wallet,
  userId,
  refetch,
  walletLoading,
}: Props) {
  const status = "withdrawal";
  const status2 = "withdrawal approved";

  const [approveReq, { data, isLoading, isSuccess, error }] =
    useUpdatePendingRequestMutation();

  const [isSeeTransaction, setIsSeeTransaction] = useState<boolean>(false);
  const [isSeeDetails, setIsSeeDetails] = useState<boolean>(false);
  const [updatedText, setUpdatedText] = useState<string>("");

  const handleApprove = (requestId: string) => {
    const body = {
      userId,
      requestId,
      status: "approved",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUpdatedText("Balance will be updated soon...");
        await approveReq(body);
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success((data as any).message);
      refetch();
    }

    if (error) {
      toast.error((error as any)?.data?.message || data?.message);
    }
  }, [data, isSuccess, error, refetch]);

  if (walletLoading || isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      {updatedText && (
        <div className="text-center font-bold my-3 text-success">
          {updatedText}
        </div>
      )}
      <div className={`${updatedText ? "mt-5" : "mt-10"}`}>
        <div className="bg-gray-200 py-3 md:py-4 rounded-full flex justify-start pl-5 items-center gap-2">
          <FaClock />
          <h1 className="font-bold text-sm md:text-[16px]">Pending Request</h1>
        </div>
        {wallet?.pending?.length > 0 ? (
          <>
            <div className="mt-8 mx-3 grid grid-cols-1 gap-y-5">
              {wallet?.pending?.map((req: any) => (
                <div className="flex flex-col gap-5" key={req?._id}>
                  <div className="flex justify-between">
                    <div className="flex justify-between items-center gap-8 md:gap-5">
                      <p className="bg-yellow-500 text-yellow-800 px-4 py-3 rounded-md">
                        W
                      </p>
                      <p className="flex flex-col">
                        <span className="font-bold md:hidden capitalize">
                          {status.length > 6
                            ? status.slice(0, 6) + "..."
                            : status}
                        </span>
                        <span className="font-bold hidden md:flex capitalize">
                          {status}
                        </span>
                        <span className="text-gray-400">
                          #{req?._id?.slice(-4)}
                        </span>
                      </p>
                    </div>
                    <div className="flex justify-between items-center gap-8 md:gap-5">
                      <p className="text-gray-400">
                        {formateDate(req?.createdAt)}
                      </p>
                      <p className="font-bold text-warning">${req?.amount}</p>
                    </div>
                  </div>
                  {isSeeDetails && (
                    <div className="mb-3">
                      <p>
                        <span className="font-semibold">Account Name: </span>
                        {req?.accountName}
                      </p>
                      <p>
                        <span className="font-semibold">Account Number: </span>
                        {req?.accountNumber}
                      </p>
                      <p>
                        <span className="font-semibold">Bank Name: </span>
                        {req?.bankName}
                      </p>
                      {req?.routingNumber && (
                        <p>
                          <span className="font-semibold">
                            Routing Number:{" "}
                          </span>
                          {req?.routingNumber}
                        </p>
                      )}
                      <p>
                        <span className="font-semibold">Swift Code: </span>
                        {req?.swiftCode}
                      </p>
                      {req?.iban && (
                        <p>
                          <span className="font-semibold">IBAN: </span>
                          {req?.iban}
                        </p>
                      )}
                      <p className="capitalize">
                        <span className="font-semibold">Country: </span>
                        {req?.country}
                      </p>
                      <button
                        onClick={() => handleApprove(req?._id)}
                        className="btn btn-sm bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white flex mx-auto mt-3"
                      >
                        Approve
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-3">
              <button onClick={() => setIsSeeDetails(!isSeeDetails)}>
                {isSeeDetails ? (
                  <MdOutlineExpandLess size={20} />
                ) : (
                  <MdOutlineExpandMore size={20} />
                )}
              </button>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center mt-10">
            <h1 className="text-lg">No Pending Request</h1>
          </div>
        )}
      </div>

      {isSeeTransaction && (
        <div className="mt-10">
          <div className="bg-gray-200 py-3 md:py-4 rounded-full flex justify-start pl-5 items-center gap-2">
            <FaClockRotateLeft />
            <h1 className="font-bold text-sm md:text-[16px]">
              Transaction History
            </h1>
          </div>
          {wallet?.transactions?.length > 0 ? (
            <div className="mt-8 mx-3 grid grid-cols-1 gap-y-5">
              {wallet?.transactions?.map((trans: any) => (
                <div className="flex justify-between" key={trans?._id}>
                  <div className="flex justify-between items-center gap-8 md:gap-5">
                    <p className="bg-red-300 text-red-600 px-4 py-3 rounded-md">
                      D
                    </p>
                    <p className="flex flex-col">
                      <span className="font-bold md:hidden capitalize">
                        {status2.length > 6
                          ? status2.slice(0, 6) + "..."
                          : status2}
                      </span>
                      <span className="font-bold hidden md:flex capitalize">
                        {status2}
                      </span>
                      <span className="text-gray-400">
                        #{trans?._id?.slice(-4)}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between items-center gap-8 md:gap-5">
                    <p className="text-gray-400">
                      {formateDate(trans?.createdAt)}
                    </p>
                    <p className="font-bold text-red-500">$-{trans?.amount}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center mt-10">
              <h1 className="text-lg">No Transaction History</h1>
            </div>
          )}
        </div>
      )}

      <div className="mt-10 flex justify-center">
        <button
          onClick={() => setIsSeeTransaction(!isSeeTransaction)}
          className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white no-animation"
        >
          {isSeeTransaction ? "Hide" : "See"} Transactions History
        </button>
      </div>
    </>
  );
}
