import GlobalLoader from "../../../../components/GlobalLoader";
import useTitle from "../../../../hooks/useTitle";
import { useGetOnlyAdminsQuery } from "../../../../redux/features/auth/AuthApi";
import AdminModal from "./AdminModal";
import AdminsCard from "./AdminsCard";

const Fade = require("react-reveal/Fade");

export default function Admins() {
  useTitle("Admins");
  const { data, isLoading } = useGetOnlyAdminsQuery({});

  const admins = data?.data;

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
      <div className="title my-2 mb-10 md:mb-5">
        <h3 className="text-2xl font-bold">Manage All Admins</h3>
        <span>
          You can manage all the admins whom are already get accessed role
        </span>
      </div>
      <div className="flex justify-center md:justify-end items-center mb-10">
        <div className="title">
          <label htmlFor={`admin-modal`} className="cursor-pointer">
            <div
              className={`btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary rounded-xl text-white`}
            >
              Add New Admin
            </div>
          </label>
        </div>
      </div>
      <Fade top distance="20px">
        {admins?.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pb-10">
            {admins?.map((admin: any, index: number) => (
              <AdminsCard key={admin._id} index={index} admin={admin} />
            ))}
          </div>
        ) : (
          <div className="card flex flex-col justify-center items-center mx-auto  w-full md:w-[300px] mt-24">
            <div className="card-body">
              <h1 className="text-center text-sm text-red-600">
                Oops! No admins found
              </h1>
            </div>
          </div>
        )}
      </Fade>
      <AdminModal />
    </div>
  );
}
