import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BsImage } from "react-icons/bs";
import { PulseLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";
import { authUserInterface } from "../../../interfaces/UserInterface";
import { useChangeProfilePictureMutation } from "../../../redux/features/auth/AuthApi";

type Props = {
  refetch: () => void;
};

const ImageChangeModal = ({ refetch }: Props) => {
  const { updatedUser } = useAuth<authUserInterface | any>({});
  const [changeProfilePicture, { data, isSuccess, error, isLoading }] =
    useChangeProfilePictureMutation();
  const { register, handleSubmit, watch, reset } = useForm();
  const [previewSource, setPreviewSource] = useState<string>("");

  const handleChangeProfile = handleSubmit(async (formDataImage) => {
    const imageInfo = formDataImage?.profileImage[0];
    const formData = new FormData();
    formData.append("profileImage", imageInfo, imageInfo?.name);
    formData.append("email", updatedUser?.email || "");
    await changeProfilePicture(formData);
  });

  watch((data, { name, type }) => {
    if (data?.profileImage?.length > 0) {
      const reader = new FileReader();
      reader?.readAsDataURL(data?.profileImage[0] as Blob);
      reader.onloadend = () => {
        setPreviewSource(reader.result as string);
      };
    }
  });

  /* Handle Data */
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
      setPreviewSource("");
      reset();
      refetch();
    }
    if (error) {
      toast.error((error as any)?.data?.message || data?.message);
    }
  }, [data, isSuccess, error, refetch, reset]);

  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleChangeProfile}
    >
      <input
        type="checkbox"
        id="profile-image-edit-modal"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-xl">Change Image</h3>

          <div className="modal-body">
            <div className="name border rounded p-3 relative mt-10 flex-1">
              <div className="name-title absolute -top-4 bg-white border rounded p-1">
                <h3 className="text-xs font-poppins">
                  Click to Put Image here
                </h3>
              </div>
              <label
                htmlFor="choose-file"
                className={`input-group flex items-center my-2 border-2 border-dotted ${!previewSource && "py-24"} rounded-md mt-2 justify-center cursor-grabbing`}
              >
                {previewSource ? (
                  <div className="w-full h-60 md:h-72 overflow-hidden mx-auto rounded-md">
                    <img
                      src={previewSource}
                      alt="previewImage"
                      className="w-full h-full object-cover rounded-md"
                    />
                  </div>
                ) : (
                  <div className="icon text-5xl">
                    <BsImage />
                  </div>
                )}
              </label>

              <input
                type="file"
                className="form-control outline-none pl-4 w-full hidden"
                id="choose-file"
                {...register("profileImage", { required: true })}
              />
            </div>
            {/* End */}
          </div>
          <div className="modal-action">
            <label
              htmlFor="profile-image-edit-modal"
              className="btn btn-sm md:btn-md btn-error text-white"
              onClick={() => {
                setPreviewSource("");
              }}
            >
              Cancel
            </label>
            {isLoading ? (
              <button
                className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white"
                disabled
              >
                <PulseLoader color="#fff" size={6} />
              </button>
            ) : (
              <button className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white">
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ImageChangeModal;
