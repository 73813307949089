import React from "react";
import { IoTextOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { authUserInterface } from "../../../../interfaces/UserInterface";
import useAuth from "../../../../hooks/useAuth";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import MiniTitle from "../../../../components/MiniTitle";
import useTitle from "../../../../hooks/useTitle";

export default function SpotifyVerifyRequest() {
  useTitle("Spotify Verify Request");

  const {
    updatedUser: user,
    refetch,
    isLoading,
  } = useAuth<authUserInterface | any>({});

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data: any) => {
    if (
      !data?.spotifyArtistName ||
      !data?.spotifyArtistPanelUrl ||
      !data?.urlOfOfficialVideo
    ) {
      return toast.error("Please fill up all the fields!");
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You want to request for Spotify Verify!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Request!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Info!",
          icon: "info",
          text: `Hey, ${user?.firstName}! This feature is not implemented yet.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
    reset();
    refetch();
  };

  return (
    <div className="md:pb-5 py-5 rounded-md">
      <MiniTitle title="Spotify Verify Request" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="name border rounded p-3 relative mt-10 flex-1">
          <div className="name-title absolute -top-5 bg-white border rounded p-1">
            <h3 className="text-sm font-bold">
              Spotify Artist Name <span className="text-red-600">*</span>
            </h3>
          </div>
          <div className="flex items-center gap-3">
            <div
              className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
            >
              <div className="icon">
                <IoTextOutline />
              </div>
              <input
                type="text"
                className={`form-control outline-none pl-4 w-full text-sm`}
                placeholder="Enter Spotify Artist Name"
                {...register("spotifyArtistName")}
              />
            </div>
          </div>
        </div>
        <div className="name border rounded p-3 relative mt-10 flex-1">
          <div className="name-title absolute -top-5 bg-white border rounded p-1">
            <h3 className="text-sm font-bold">
              Spotify Artist Panel Url <span className="text-red-600">*</span>
            </h3>
          </div>
          <div className="flex items-center gap-3">
            <div
              className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
            >
              <div className="icon">
                <IoTextOutline />
              </div>
              <input
                type="url"
                className={`form-control outline-none pl-4 w-full text-sm`}
                placeholder="Enter Spotify Artist Panel Url"
                {...register("spotifyArtistPanelUrl")}
              />
            </div>
          </div>
        </div>
        <div className="name border rounded p-3 relative mt-10 flex-1">
          <div className="name-title absolute -top-5 bg-white border rounded p-1">
            <h3 className="text-sm font-bold">
              URL of official Video of Art Track we distributed{" "}
              <span className="text-red-600">*</span>
            </h3>
          </div>
          <div className="flex items-center gap-3">
            <div
              className={`input-group flex items-center my-2 border p-3 rounded-md mt-2`}
            >
              <div className="icon">
                <IoTextOutline />
              </div>
              <input
                type="url"
                className={`form-control outline-none pl-4 w-full text-sm`}
                placeholder="Enter URL of official Video"
                {...register("urlOfOfficialVideo")}
              />
            </div>
          </div>
        </div>

        <div className="mt-5 flex justify-center items-center">
          {isLoading ? (
            <button
              className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary"
              type="button"
            >
              <PulseLoader size={8} color="#fff" />
            </button>
          ) : (
            <button
              className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white"
              type="submit"
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
