import { useEffect, useState } from "react";
import GlobalLoader from "../../../../components/GlobalLoader";
import MiniTitle from "../../../../components/MiniTitle";
import SearchInfo from "../../../../components/SearchInfo";
import useTitle from "../../../../hooks/useTitle";
import { useGetAllWaitingUserByStatusQuery } from "../../../../redux/features/WaitingUserApi";
import DetailsCard from "./DetailsCard";

const Fade = require("react-reveal/Fade");

export default function RejectedApplications() {
  useTitle("Rejected applications");

  const { data, isLoading, refetch } = useGetAllWaitingUserByStatusQuery({
    status: "rejected",
  });

  const applications = data?.data;

  const [searchedData, setSearchedData] = useState<any[]>([]);

  useEffect(() => {
    if (!searchedData?.length) {
      setSearchedData(applications);
      refetch();
    }
  }, [searchedData, applications, refetch]);

  const handleSearchInfo = async (e: any) => {
    e.preventDefault();
    const value = e.target.value.toLowerCase();
    const filter = applications?.filter((item: any) => {
      return (
        item?.firstName?.toLowerCase()?.includes(value) ||
        item?.lastName?.toLowerCase()?.includes(value) ||
        item?.email?.toLowerCase()?.includes(value) ||
        item?.phoneNumber?.toLowerCase()?.includes(value)
      );
    });
    const getData = setTimeout(() => {
      setSearchedData(filter);
    }, 1000);

    return () => clearTimeout(getData);
  };

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Fade top distance="20px">
      <div className="py-0 md:pb-5 lg:py-5 rounded-md">
        <MiniTitle title="Rejected applications" />
        <SearchInfo handleSearchInfo={handleSearchInfo} />
        {searchedData?.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 pb-10">
            {searchedData?.map((user: any, index: number) => (
              <DetailsCard key={user._id} index={index} user={user} />
            ))}
          </div>
        ) : (
          <div className="card flex flex-col justify-center items-center mx-auto w-full md:w-[300px] mt-24">
            <div className="card-body">
              <h1 className="text-center text-sm text-red-600">
                Oops! No rejected applications found
              </h1>
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
}
