import React from "react";
import { PulseLoader } from "react-spinners";
import GlobalLoader from "../../../../components/GlobalLoader";

type Props = {
  user: any;
  isLoading: boolean;
  percentageLoading: boolean;
  handleUpdatePercentage: any;
  register: any;
};

export default function PercentageUpdateModal({
  user,
  isLoading,
  percentageLoading,
  handleUpdatePercentage,
  register,
}: Props) {
  return (
    <>
      {isLoading || percentageLoading ? (
        <GlobalLoader />
      ) : (
        <form onSubmit={handleUpdatePercentage}>
          <input
            type="checkbox"
            id="updatePercentage"
            className="modal-toggle"
          />
          <div className={`modal modal-bottom sm:modal-middle`}>
            <div className="modal-box md:w-2/3 lg:w-1/3 md:max-w-5xl">
              <h3 className="font-bold text-xl">Update Percentage</h3>
              <p>
                Update percentage for{" "}
                <span className="font-bold text-gradientPrimary">
                  {user?.email}
                </span>
              </p>
              <div className="name border rounded p-3 relative mt-10 flex-1">
                <div className="name-title absolute -top-4 bg-white border rounded p-1">
                  <h3 className="text-xs font-poppins">Percentage</h3>
                </div>
                <div className="flex items-center gap-3">
                  <div
                    className={`input-group flex items-center my-2 p-3 border rounded-md mt-2`}
                  >
                    <div className="icon">%</div>
                    <input
                      type="number"
                      className={`form-control outline-none pl-4 w-full text-sm`}
                      placeholder="Enter percentage"
                      defaultValue={user?.profitPercentage}
                      {...register("profitPercentage")}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-action">
                <label
                  htmlFor="updatePercentage"
                  className="btn btn-sm md:btn-md btn-error text-white"
                >
                  Close
                </label>
                {isLoading || percentageLoading ? (
                  <button
                    className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary"
                    type="button"
                  >
                    <PulseLoader size={8} color="#fff" />
                  </button>
                ) : (
                  <button
                    className="btn btn-sm md:btn-md bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
