import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import { authUserInterface } from "../../interfaces/UserInterface";
import { useVerificationMailMutation } from "../../redux/features/auth/AuthApi";

export default function VerifyMail() {
  useTitle("Verify Mail");
  const { user } = useAuth<authUserInterface | any>({});
  const [verifyMail, { data, isSuccess, isError, error }] =
    useVerificationMailMutation();
  const [isSent, setIsSent] = useState<boolean>(false);
  const navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm();

  const handleVerifyMail = handleSubmit(async (formData) => {
    if (!formData?.verifyMailEmail) return toast.error("Email is required");
    await verifyMail({ email: formData?.verifyMailEmail });
  });

  useEffect(() => {
    if (isError) {
      toast.error((error as any)?.data?.message);
    }

    if (isSuccess) {
      toast.success(data?.message);
      setIsSent(true);
      reset();
    }

    if (user?.isAuthenticated) {
      navigate("/");
    }
  }, [isSuccess, data, error, isError, navigate, reset, user]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <div className="card w-full md:w-2/3 lg:w-1/3 bg-base-100 shadow-xl mx-3 md:mx-0">
        <div className="card-body">
          {isSent && (
            <div className="alert rounded bg-green-50 text-green-500 mb-3">
              Email successfully sent
            </div>
          )}
          <h2 className="card-title">Verify Mail</h2>
          <p>
            After click below this button. It will send you email with verify
            link and this link will valid only for 1 hour. After 1 hour this
            link will not work anyway.
          </p>
          <form onSubmit={handleVerifyMail} className="mt-4">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                className="py-7 input input-bordered w-full"
                {...register("verifyMailEmail")}
              />
            </div>
            <button className="btn flex justify-center items-center mx-auto btn-error mt-6 text-white">
              Send Verification Link
            </button>
          </form>
          <div>
            <Link
              to="/login"
              className="text-center text-success uppercase block mt-4 font-semibold"
            >
              Go to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
