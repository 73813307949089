import { baseApi } from "../baseApi";

export const songApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadSong: builder.mutation({
      query: (body) => ({
        url: "/songs/upload-song",
        method: "POST",
        body,
      }),
      invalidatesTags: ["song"],
    }),
    updateSong: builder.mutation({
      query: ({ id, data }) => ({
        url: `/songs/update-song/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["song"],
    }),
    getAllReleases: builder.query({
      query: () => ({
        url: "/songs/get-all-releases",
        method: "GET",
      }),
      providesTags: ["song"],
    }),
    getAllReleasesByArtist: builder.query({
      query: ({ id, status }) => ({
        url: `/songs/get-all-releases-by-user?id=${id}&status=${status}`,
        method: "GET",
      }),
      providesTags: ["song"],
    }),
    getSongById: builder.query({
      query: ({ id }) => ({
        url: `/songs/get-song?id=${id}`,
        method: "GET",
      }),
      providesTags: ["song"],
    }),
    getAllSongsByStatus: builder.query({
      query: ({ status }) => ({
        url: `/songs/get-all-releases-by-status?status=${status}`,
        method: "GET",
      }),
      providesTags: ["song"],
    }),
    approveSongStatus: builder.mutation({
      query: (body) => ({
        url: `/songs/approve-song-status`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["song"],
    }),
    rejectSongStatus: builder.mutation({
      query: (body) => ({
        url: `/songs/reject-song-status`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["song"],
    }),
    takedownSong: builder.mutation({
      query: (body) => ({
        url: `/songs/takedown-song`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["song"],
    }),
  }),
});

export const {
  useUploadSongMutation,
  useUpdateSongMutation,
  useGetAllReleasesQuery,
  useGetAllReleasesByArtistQuery,
  useGetSongByIdQuery,
  useGetAllSongsByStatusQuery,
  useApproveSongStatusMutation,
  useRejectSongStatusMutation,
  useTakedownSongMutation,
} = songApi;
