import { format } from "date-fns";
import toast from "react-hot-toast";

export const numberOfTracks = [
  {
    id: 1,
    name: "1 song (a single)",
    value: 1,
  },
  // {
  //           "id": 2,
  //           "name": "2 songs",
  //           "value": 2
  // },
  // {
  //           "id": 3,
  //           "name": "3 songs",
  //           "value": 3
  // },
  // {
  //           "id": 4,
  //           "name": "4 songs",
  //           "value": 4
  // },
  // {
  //           "id": 5,
  //           "name": "5 songs",
  //           "value": 5
  // },
  // {
  //           "id": 6,
  //           "name": "6 songs",
  //           "value": 6
  // },
  // {
  //           "id": 7,
  //           "name": "7 songs",
  //           "value": 7
  // },
  // {
  //           "id": 8,
  //           "name": "8 songs",
  //           "value": 8
  // },
  // {
  //           "id": 9,
  //           "name": "9 songs",
  //           "value": 9
  // },
  // {
  //           "id": 10,
  //           "name": "10 songs",
  //           "value": 10
  // },
  // {
  //           "id": 11,
  //           "name": "11 songs",
  //           "value": 11
  // },
  // {
  //           "id": 12,
  //           "name": "12 songs",
  //           "value": 12
  // },
  // {
  //           "id": 13,
  //           "name": "13 songs",
  //           "value": 13
  // },
  // {
  //           "id": 14,
  //           "name": "14 songs",
  //           "value": 14
  // },
  // {
  //           "id": 15,
  //           "name": "15 songs",
  //           "value": 15
  // },
  // {
  //           "id": 16,
  //           "name": "16 songs",
  //           "value": 16
  // },
  // {
  //           "id": 17,
  //           "name": "17 songs",
  //           "value": 17
  // },
  // {
  //           "id": 18,
  //           "name": "18 songs",
  //           "value": 18
  // },
  // {
  //           "id": 19,
  //           "name": "19 songs",
  //           "value": 19
  // },
  // {
  //           "id": 20,
  //           "name": "20 songs",
  //           "value": 20
  // },
];

export const language = [
  {
    id: 1,
    name: "Arabic",
    value: "arabic",
  },
  {
    id: 2,
    name: "Bengali",
    value: "bengali",
  },
  {
    id: 3,
    name: "Bosnian",
    value: "bosnian",
  },
  {
    id: 4,
    name: "Bulgarian",
    value: "bulgarian",
  },
  {
    id: 5,
    name: "Cantonese",
    value: "cantonese",
  },
  {
    id: 6,
    name: "Catalan",
    value: "catalan",
  },
  {
    id: 7,
    name: "Chinese (Simplified)",
    value: "chineseSimplified",
  },
  {
    id: 8,
    name: "Chinese (Traditional)",
    value: "chineseTraditional",
  },
  {
    id: 9,
    name: "Croatian",
    value: "croatian",
  },
  {
    id: 10,
    name: "Czech",
    value: "czech",
  },
  {
    id: 11,
    name: "Danish",
    value: "danish",
  },
  {
    id: 12,
    name: "Dutch",
    value: "dutch",
  },
  {
    id: 13,
    name: "English",
    value: "english",
    selected: true,
  },
  {
    id: 14,
    name: "Estonian",
    value: "estonian",
  },
  {
    id: 15,
    name: "Finnish",
    value: "finnish",
  },
  {
    id: 16,
    name: "French",
    value: "french",
  },
  {
    id: 17,
    name: "German",
    value: "german",
  },
  {
    id: 18,
    name: "Greek",
    value: "greek",
  },
  {
    id: 19,
    name: "Hebrew",
    value: "hebrew",
  },
  {
    id: 20,
    name: "Hindi",
    value: "hindi",
  },
  {
    id: 21,
    name: "Hungarian",
    value: "hungarian",
  },
  {
    id: 22,
    name: "Icelandic",
    value: "icelandic",
  },
  {
    id: 23,
    name: "Indonesian",
    value: "indonesian",
  },
  {
    id: 24,
    name: "Italian",
    value: "italian",
  },
  {
    id: 25,
    name: "Japanese",
    value: "japanese",
  },
  {
    id: 26,
    name: "Korean",
    value: "korean",
  },
  {
    id: 27,
    name: "Latvian",
    value: "latvian",
  },
  {
    id: 28,
    name: "Lithuanian",
    value: "lithuanian",
  },
  {
    id: 29,
    name: "Malay",
    value: "malay",
  },
  {
    id: 30,
    name: "Mandarin",
    value: "mandarin",
  },
  {
    id: 31,
    name: "Norwegian",
    value: "norwegian",
  },
  {
    id: 32,
    name: "Persian",
    value: "persian",
  },
  {
    id: 33,
    name: "Polish",
    value: "polish",
  },
  {
    id: 34,
    name: "Portuguese",
    value: "portuguese",
  },
  {
    id: 35,
    name: "Romanian",
    value: "romanian",
  },
  {
    id: 36,
    name: "Russian",
    value: "russian",
  },
  {
    id: 37,
    name: "Serbian",
    value: "serbian",
  },
  {
    id: 38,
    name: "Slovak",
    value: "slovak",
  },
  {
    id: 39,
    name: "Slovenian",
    value: "slovenian",
  },
  {
    id: 40,
    name: "Spanish",
    value: "spanish",
  },
  {
    id: 41,
    name: "Swedish",
    value: "swedish",
  },
  {
    id: 42,
    name: "Thai",
    value: "thai",
  },
  {
    id: 43,
    name: "Turkish",
    value: "turkish",
  },
  {
    id: 44,
    name: "Ukrainian",
    value: "ukrainian",
  },
  {
    id: 45,
    name: "Vietnamese",
    value: "vietnamese",
  },
];

export const primaryGenre = [
  {
    id: 1,
    name: "Select from a list of genres",
    value: "",
  },
  {
    id: 2,
    name: "Afrobeat",
    value: "afrobeat",
  },
  {
    id: 3,
    name: "Afropop",
    value: "afropop",
  },
  {
    id: 4,
    name: "Alternative",
    value: "alternative",
  },
  {
    id: 5,
    name: "Big Band",
    value: "bigBand",
  },
  {
    id: 6,
    name: "Blues",
    value: "blues",
  },
  {
    id: 7,
    name: "Children's Music",
    value: "childrenMusic",
  },
  {
    id: 8,
    name: "Christian/Gospel",
    value: "christianGospel",
  },
  {
    id: 9,
    name: "Classical",
    value: "classical",
  },
  {
    id: 10,
    name: "Comedy",
    value: "comedy",
  },
  {
    id: 11,
    name: "Country",
    value: "country",
  },
  {
    id: 12,
    name: "Dance",
    value: "dance",
  },
  {
    id: 13,
    name: "Electronic",
    value: "electronic",
  },
  {
    id: 14,
    name: "Fitness &amp; Workout",
    value: "fitnessWorkout",
  },
  {
    id: 15,
    name: "Folk",
    value: "folk",
  },
  {
    id: 16,
    name: "French Pop",
    value: "frenchPop",
  },
  {
    id: 17,
    name: "German Folk",
    value: "germanFolk",
  },
  {
    id: 18,
    name: "German Pop",
    value: "germanPop",
  },
  {
    id: 19,
    name: "Hip Hop/Rap",
    value: "hipHopRap",
  },
  {
    id: 20,
    name: "Holiday",
    value: "holiday",
  },
  {
    id: 21,
    name: "J-Pop",
    value: "jPop",
  },
  {
    id: 22,
    name: "Jazz",
    value: "jazz",
  },
  {
    id: 23,
    name: "J-Pop",
    value: "jPop",
  },
  {
    id: 24,
    name: "Jazz",
    value: "jazz",
  },
  {
    id: 25,
    name: "K-Pop",
    value: "kPop",
  },
  {
    id: 26,
    name: "Karaoke",
    value: "karaoke",
  },
  {
    id: 27,
    name: "Latin",
    value: "latin",
  },
  {
    id: 28,
    name: "Latin Urban",
    value: "latinUrban",
  },
  {
    id: 29,
    name: "Metal",
    value: "metal",
  },
  {
    id: 30,
    name: "New Age",
    value: "newAge",
  },
  {
    id: 31,
    name: "Pop",
    value: "pop",
  },
  {
    id: 32,
    name: "Pop",
    value: "pop",
  },
  {
    id: 33,
    name: "Punk",
    value: "punk",
  },
  {
    id: 34,
    name: "R&amp;B/Soul",
    value: "rBSoul",
  },
  {
    id: 35,
    name: "Reggae",
    value: "reggae",
  },
  {
    id: 36,
    name: "Rock",
    value: "rock",
  },
  {
    id: 37,
    name: "Singer/Songwriter",
    value: "singerSongwriter",
  },
  {
    id: 38,
    name: "Soundtrack",
    value: "soundtrack",
  },
  {
    id: 39,
    name: "Spoken Word",
    value: "spokenWord",
  },
  {
    id: 40,
    name: "Tex-Mex/Tejano",
    value: "texMexTejano",
  },
  {
    id: 41,
    name: "Vocal",
    value: "vocal",
  },
  {
    id: 42,
    name: "World",
    value: "world",
  },
];

export const secondaryGenre = [
  {
    id: 1,
    name: "Select from a list of genres",
    value: "",
  },
  {
    id: 2,
    name: "Afrobeat",
    value: "afrobeat",
  },
  {
    id: 3,
    name: "Afropop",
    value: "afropop",
  },
  {
    id: 4,
    name: "Alternative",
    value: "alternative",
  },
  {
    id: 5,
    name: "Big Band",
    value: "bigBand",
  },
  {
    id: 6,
    name: "Blues",
    value: "blues",
  },
  {
    id: 7,
    name: "Children's Music",
    value: "childrenMusic",
  },
  {
    id: 8,
    name: "Christian/Gospel",
    value: "christianGospel",
  },
  {
    id: 9,
    name: "Classical",
    value: "classical",
  },
  {
    id: 10,
    name: "Comedy",
    value: "comedy",
  },
  {
    id: 11,
    name: "Country",
    value: "country",
  },
  {
    id: 12,
    name: "Dance",
    value: "dance",
  },
  {
    id: 13,
    name: "Electronic",
    value: "electronic",
  },
  {
    id: 14,
    name: "Fitness &amp; Workout",
    value: "fitnessWorkout",
  },
  {
    id: 15,
    name: "Folk",
    value: "folk",
  },
  {
    id: 16,
    name: "French Pop",
    value: "frenchPop",
  },
  {
    id: 17,
    name: "German Folk",
    value: "germanFolk",
  },
  {
    id: 18,
    name: "German Pop",
    value: "germanPop",
  },
  {
    id: 19,
    name: "Hip Hop/Rap",
    value: "hipHopRap",
  },
  {
    id: 20,
    name: "Holiday",
    value: "holiday",
  },
  {
    id: 21,
    name: "J-Pop",
    value: "jPop",
  },
  {
    id: 22,
    name: "Jazz",
    value: "jazz",
  },
  {
    id: 23,
    name: "J-Pop",
    value: "jPop",
  },
  {
    id: 24,
    name: "Jazz",
    value: "jazz",
  },
  {
    id: 25,
    name: "K-Pop",
    value: "kPop",
  },
  {
    id: 26,
    name: "Karaoke",
    value: "karaoke",
  },
  {
    id: 27,
    name: "Latin",
    value: "latin",
  },
  {
    id: 28,
    name: "Latin Urban",
    value: "latinUrban",
  },
  {
    id: 29,
    name: "Metal",
    value: "metal",
  },
  {
    id: 30,
    name: "New Age",
    value: "newAge",
  },
  {
    id: 31,
    name: "Pop",
    value: "pop",
  },
  {
    id: 32,
    name: "Pop",
    value: "pop",
  },
  {
    id: 33,
    name: "Punk",
    value: "punk",
  },
  {
    id: 34,
    name: "R&amp;B/Soul",
    value: "rBSoul",
  },
  {
    id: 35,
    name: "Reggae",
    value: "reggae",
  },
  {
    id: 36,
    name: "Rock",
    value: "rock",
  },
  {
    id: 37,
    name: "Singer/Songwriter",
    value: "singerSongwriter",
  },
  {
    id: 38,
    name: "Soundtrack",
    value: "soundtrack",
  },
  {
    id: 39,
    name: "Spoken Word",
    value: "spokenWord",
  },
  {
    id: 40,
    name: "Tex-Mex/Tejano",
    value: "texMexTejano",
  },
  {
    id: 41,
    name: "Vocal",
    value: "vocal",
  },
  {
    id: 42,
    name: "World",
    value: "world",
  },
];

export const services = [
  {
    id: 1,
    name: "All store (YT Music, Apple Music, Spotify, TikTok and others)",
    value: "allStore",
    selected: true,
  },
  {
    id: 2,
    name: "Youtube Content ID",
    value: "youtubeContentID",
    selected: false,
  },
];

export const youtubeContentID = [
  {
    id: 1,
    name: "I created all of the sounds myself",
    value: true,
  },
  {
    id: 2,
    name: "This single does NOT contain beats, loops, sound effects, or other audio that I downloaded from sample libraries or other public sources. This includes sounds that are available for free from GarageBand, Ableton, Logic, FL Studio, etc.",
    value: true,
  },
  {
    id: 3,
    name: "I promise to NOT allow other artists to remix this single, or use any of the sounds from this single in their own songs. That's because if their remix gets uploaded to YouTube, Content ID may claim it as yours. Or if the remixer opts into Content ID, it may think your version is the same song, and claim it as theirs. It's all very complicated.",
    value: true,
  },
  {
    id: 4,
    name: "This single does NOT contain public domain recordings or songs.",
    value: true,
  },
  {
    id: 5,
    name: "This single does NOT contain sounds from video games, television, movies, etc.",
    value: true,
  },
  {
    id: 6,
    name: "This single does NOT contain any audio from other peoples' YouTube videos.",
    value: true,
  },
  {
    id: 7,
    name: "I'm the only person who will add this single to YouTube Content ID. Nobody else (band members, producer, etc.) will also try to add it to YouTube Content ID. Because that causes a mess.",
    value: true,
  },
  {
    id: 8,
    name: "I have NOT used another company, such as TuneCore or CD Baby, to submit this single to YouTube Content ID. That will cause a conflict, and a mess.",
    value: true,
  },
  {
    id: 9,
    name: "YouTube is really serious about this stuff. If any of the previous statements are found to be untrue, I understand that my Mirror-LTD Music account may be suspended. And worst case, I'll be liable for any fees, legal or otherwise, that may accrue.",
    value: true,
  },
];

export const countryOptions = [
  { value: "afghanistan", label: "Afghanistan" },
  { value: "albania", label: "Albania" },
  { value: "algeria", label: "Algeria" },
  { value: "andorra", label: "Andorra" },
  { value: "angola", label: "Angola" },
  { value: "antigua-and-barbuda", label: "Antigua and Barbuda" },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia" },
  { value: "australia", label: "Australia" },
  { value: "austria", label: "Austria" },
  { value: "azerbaijan", label: "Azerbaijan" },
  { value: "bahamas", label: "Bahamas" },
  { value: "bahrain", label: "Bahrain" },
  { value: "bangladesh", selected: true, label: "Bangladesh" },
  { value: "barbados", label: "Barbados" },
  { value: "belarus", label: "Belarus" },
  { value: "belgium", label: "Belgium" },
  { value: "belize", label: "Belize" },
  { value: "benin", label: "Benin" },
  { value: "bhutan", label: "Bhutan" },
  { value: "bolivia", label: "Bolivia" },
  { value: "bosnia-and-herzegovina", label: "Bosnia and Herzegovina" },
  { value: "botswana", label: "Botswana" },
  { value: "brazil", label: "Brazil" },
  { value: "brunei", label: "Brunei" },
  { value: "bulgaria", label: "Bulgaria" },
  { value: "burkina-faso", label: "Burkina Faso" },
  { value: "burundi", label: "Burundi" },
  { value: "cabo-verde", label: "Cabo Verde" },
  { value: "cambodia", label: "Cambodia" },
  { value: "cameroon", label: "Cameroon" },
  { value: "canada", label: "Canada" },
  { value: "central-african-republic", label: "Central African Republic" },
  { value: "chad", label: "Chad" },
  { value: "chile", label: "Chile" },
  { value: "china", label: "China" },
  { value: "colombia", label: "Colombia" },
  { value: "comoros", label: "Comoros" },
  { value: "congo", label: "Congo" },
  { value: "costa-rica", label: "Costa Rica" },
  { value: "croatia", label: "Croatia" },
  { value: "cuba", label: "Cuba" },
  { value: "cyprus", label: "Cyprus" },
  { value: "czech-republic", label: "Czech Republic" },
  { value: "denmark", label: "Denmark" },
  { value: "djibouti", label: "Djibouti" },
  { value: "dominica", label: "Dominica" },
  { value: "dominican-republic", label: "Dominican Republic" },
  { value: "east-timor", label: "East Timor" },
  { value: "ecuador", label: "Ecuador" },
  { value: "egypt", label: "Egypt" },
  { value: "el-salvador", label: "El Salvador" },
  { value: "equatorial-guinea", label: "Equatorial Guinea" },
  { value: "eritrea", label: "Eritrea" },
  { value: "estonia", label: "Estonia" },
  { value: "eswatini", label: "Eswatini" },
  { value: "ethiopia", label: "Ethiopia" },
  { value: "fiji", label: "Fiji" },
  { value: "finland", label: "Finland" },
  { value: "france", label: "France" },
  { value: "gabon", label: "Gabon" },
  { value: "gambia", label: "Gambia" },
  { value: "georgia", label: "Georgia" },
  { value: "germany", label: "Germany" },
  { value: "ghana", label: "Ghana" },
  { value: "greece", label: "Greece" },
  { value: "grenada", label: "Grenada" },
  { value: "guatemala", label: "Guatemala" },
  { value: "guinea", label: "Guinea" },
  { value: "guinea-bissau", label: "Guinea-Bissau" },
  { value: "guyana", label: "Guyana" },
  { value: "haiti", label: "Haiti" },
  { value: "honduras", label: "Honduras" },
  { value: "hungary", label: "Hungary" },
  { value: "iceland", label: "Iceland" },
  { value: "india", label: "India" },
  { value: "indonesia", label: "Indonesia" },
  { value: "iran", label: "Iran" },
  { value: "iraq", label: "Iraq" },
  { value: "ireland", label: "Ireland" },
  { value: "israel", label: "Israel" },
  { value: "italy", label: "Italy" },
  { value: "ivory-coast", label: "Ivory Coast" },
  { value: "jamaica", label: "Jamaica" },
  { value: "japan", label: "Japan" },
  { value: "jordan", label: "Jordan" },
  { value: "kazakhstan", label: "Kazakhstan" },
  { value: "kenya", label: "Kenya" },
  { value: "kiribati", label: "Kiribati" },
  { value: "kosovo", label: "Kosovo" },
  { value: "kuwait", label: "Kuwait" },
  { value: "kyrgyzstan", label: "Kyrgyzstan" },
  { value: "laos", label: "Laos" },
  { value: "latvia", label: "Latvia" },
  { value: "lebanon", label: "Lebanon" },
  { value: "lesotho", label: "Lesotho" },
  { value: "liberia", label: "Liberia" },
  { value: "libya", label: "Libya" },
  { value: "liechtenstein", label: "Liechtenstein" },
  { value: "lithuania", label: "Lithuania" },
  { value: "luxembourg", label: "Luxembourg" },
  { value: "madagascar", label: "Madagascar" },
  { value: "malawi", label: "Malawi" },
  { value: "malaysia", label: "Malaysia" },
  { value: "maldives", label: "Maldives" },
  { value: "mali", label: "Mali" },
  { value: "malta", label: "Malta" },
  { value: "marshall-islands", label: "Marshall Islands" },
  { value: "mauritania", label: "Mauritania" },
  { value: "mauritius", label: "Mauritius" },
  { value: "mexico", label: "Mexico" },
  { value: "micronesia", label: "Micronesia" },
  { value: "moldova", label: "Moldova" },
  { value: "monaco", label: "Monaco" },
  { value: "mongolia", label: "Mongolia" },
  { value: "montenegro", label: "Montenegro" },
  { value: "morocco", label: "Morocco" },
  { value: "mozambique", label: "Mozambique" },
  { value: "myanmar", label: "Myanmar" },
  { value: "namibia", label: "Namibia" },
  { value: "nauru", label: "Nauru" },
  { value: "nepal", label: "Nepal" },
  { value: "netherlands", label: "Netherlands" },
  { value: "new-zealand", label: "New Zealand" },
  { value: "nicaragua", label: "Nicaragua" },
  { value: "niger", label: "Niger" },
  { value: "nigeria", label: "Nigeria" },
  { value: "north-korea", label: "North Korea" },
  { value: "north-macedonia", label: "North Macedonia" },
  { value: "norway", label: "Norway" },
  { value: "oman", label: "Oman" },
  { value: "pakistan", label: "Pakistan" },
  { value: "palau", label: "Palau" },
  { value: "palestine", label: "Palestine" },
  { value: "panama", label: "Panama" },
  { value: "papua-new-guinea", label: "Papua New Guinea" },
  { value: "paraguay", label: "Paraguay" },
  { value: "peru", label: "Peru" },
  { value: "philippines", label: "Philippines" },
  { value: "poland", label: "Poland" },
  { value: "portugal", label: "Portugal" },
  { value: "qatar", label: "Qatar" },
  { value: "romania", label: "Romania" },
  { value: "russia", label: "Russia" },
  { value: "rwanda", label: "Rwanda" },
  { value: "saint-kitts-and-nevis", label: "Saint Kitts and Nevis" },
  { value: "saint-lucia", label: "Saint Lucia" },
  {
    value: "saint-vincent-and-the-grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "samoa", label: "Samoa" },
  { value: "san-marino", label: "San Marino" },
  { value: "sao-tome-and-principe", label: "Sao Tome and Principe" },
  { value: "saudi-arabia", label: "Saudi Arabia" },
  { value: "senegal", label: "Senegal" },
  { value: "serbia", label: "Serbia" },
  { value: "seychelles", label: "Seychelles" },
  { value: "sierra-leone", label: "Sierra Leone" },
  { value: "singapore", label: "Singapore" },
  { value: "slovakia", label: "Slovakia" },
  { value: "slovenia", label: "Slovenia" },
  { value: "solomon-islands", label: "Solomon Islands" },
  { value: "somalia", label: "Somalia" },
  { value: "south-africa", label: "South Africa" },
  { value: "south-korea", label: "South Korea" },
  { value: "south-sudan", label: "South Sudan" },
  { value: "spain", label: "Spain" },
  { value: "sri-lanka", label: "Sri Lanka" },
  { value: "sudan", label: "Sudan" },
  { value: "suriname", label: "Suriname" },
  { value: "sweden", label: "Sweden" },
  { value: "switzerland", label: "Switzerland" },
  { value: "syria", label: "Syria" },
  { value: "taiwan", label: "Taiwan" },
  { value: "tajikistan", label: "Tajikistan" },
  { value: "tanzania", label: "Tanzania" },
  { value: "thailand", label: "Thailand" },
  { value: "togo", label: "Togo" },
  { value: "tonga", label: "Tonga" },
  { value: "trinidad-and-tobago", label: "Trinidad and Tobago" },
  { value: "tunisia", label: "Tunisia" },
  { value: "turkey", label: "Turkey" },
  { value: "turkmenistan", label: "Turkmenistan" },
  { value: "tuvalu", label: "Tuvalu" },
  { value: "uganda", label: "Uganda" },
  { value: "ukraine", label: "Ukraine" },
  { value: "united-arab-emirates", label: "United Arab Emirates" },
  { value: "united-kingdom", label: "United Kingdom" },
  { value: "united-states", label: "United States" },
  { value: "uruguay", label: "Uruguay" },
  { value: "uzbekistan", label: "Uzbekistan" },
  { value: "vanuatu", label: "Vanuatu" },
  { value: "vatican-city", label: "Vatican City" },
  { value: "venezuela", label: "Venezuela" },
  { value: "vietnam", label: "Vietnam" },
  { value: "yemen", label: "Yemen" },
  { value: "zambia", label: "Zambia" },
  { value: "zimbabwe", label: "Zimbabwe" },
];

export const formateDate = (date: any) => {
  return format(new Date(date), "MMM dd, yyyy");
};

export const handleDownload = (url: string, title: string) => {
  const fileTitle = title.split("/").pop() as string;
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const fileUrl = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.download = fileTitle;
      alink.click();
    });
};

export const handleCopiedToClipboard = (text: string, title: string) => {
  navigator.clipboard.writeText(text);
  toast.success(`${title} copied to clipboard`);
};

export const findGenre = (genre: string) => {
  const find = primaryGenre.find((item) => item.value === genre);
  return find?.name;
};

export const findService = (service: string) => {
  const find = services.find((item) => item.value === service);
  return find?.name;
};
