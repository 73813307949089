import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "universal-cookie";
import { BASE_API } from "../configs/config";

const cookies = new Cookies();

export interface CustomError {
  data: {
    message: string;
    success: boolean;
  };
  status: number;
}

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    mode: "cors",
    prepareHeaders: (headers, api) => {
      const cookie = cookies.get("user");
      const token = cookie?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("Access-Control-Allow-Origin", "*");
        headers.set(
          "Access-Control-Allow-Methods",
          "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        );
      }
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  endpoints: () => ({}),
  tagTypes: [
    "user",
    "claimRequest",
    "verifyRequest",
    "waitingUser",
    "wallet",
    "song",
  ],
});
