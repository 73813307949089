import { baseApi } from "../baseApi";

export const walletApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createPendingRequest: builder.mutation({
      query: (body) => ({
        url: "/wallets",
        method: "POST",
        body,
      }),
      invalidatesTags: ["wallet"],
    }),
    updatePendingRequest: builder.mutation({
      query: (body) => ({
        url: `/wallets/update-pending-request`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["wallet", "user"],
    }),
    getWalletByUserId: builder.query({
      query: ({ userId }) => ({
        url: `/wallets/user/${userId}`,
        method: "GET",
      }),
      providesTags: ["wallet", "user"],
    }),
    getWalletById: builder.query({
      query: ({ id }) => ({
        url: `/wallets/wallet/${id}`,
        method: "GET",
      }),
      providesTags: ["wallet"],
    }),
    getAllPendingWalletRequests: builder.query({
      query: () => ({
        url: `/wallets/all-pending-requests`,
        method: "GET",
      }),
      providesTags: ["wallet"],
    }),
  }),
});

export const {
  useCreatePendingRequestMutation,
  useUpdatePendingRequestMutation,
  useGetWalletByUserIdQuery,
  useGetWalletByIdQuery,
  useGetAllPendingWalletRequestsQuery,
} = walletApi;
