import axios from "axios";
import { Suspense, useEffect } from "react";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import AuthChangeRoute from "./auth/AuthChangeRoute";
import RequireAdmin from "./auth/RequireAdmin";
import RequireAuth from "./auth/RequireAuth";
import HomeLoader from "./components/HomeLoader";
import { BASE_API } from "./configs/config";
import Login from "./pages/Authentication/Login";
import PasswordChangeField from "./pages/Authentication/PasswordChangeField";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ResetPasswordField from "./pages/Authentication/ResetPasswordField";
import VerifyMail from "./pages/Authentication/VerifyMail";
import Admins from "./pages/Dashboard/AdminDashboard/Admins/Admins";
import Applications from "./pages/Dashboard/AdminDashboard/Applications/Applications";
import ApprovedApplications from "./pages/Dashboard/AdminDashboard/Applications/ApprovedApplications";
import PendingApplications from "./pages/Dashboard/AdminDashboard/Applications/PendingApplications";
import RejectedApplications from "./pages/Dashboard/AdminDashboard/Applications/RejectedApplications";
import ClaimRequests from "./pages/Dashboard/AdminDashboard/ClaimRequests/ClaimRequests";
import EditRelease from "./pages/Dashboard/AdminDashboard/EditRelease/EditRelease";
import ApprovedAllReleases from "./pages/Dashboard/AdminDashboard/ManageAllReleases/ApprovedAllReleases";
import ManageAllReleases from "./pages/Dashboard/AdminDashboard/ManageAllReleases/ManageAllReleases";
import PendingAllReleases from "./pages/Dashboard/AdminDashboard/ManageAllReleases/PendingAllReleases";
import RejectedAllReleases from "./pages/Dashboard/AdminDashboard/ManageAllReleases/RejectedAllReleases";
import TakedownAllReleases from "./pages/Dashboard/AdminDashboard/ManageAllReleases/TakedownAllReleases";
import ApprovedVerifyRequests from "./pages/Dashboard/AdminDashboard/ManageVerifyRequests/ApprovedVerifyRequests";
import ManageVerifyRequests from "./pages/Dashboard/AdminDashboard/ManageVerifyRequests/ManageVerifyRequests";
import PendingVerifyRequests from "./pages/Dashboard/AdminDashboard/ManageVerifyRequests/PendingVerifyRequests";
import RejectedVerifyRequests from "./pages/Dashboard/AdminDashboard/ManageVerifyRequests/RejectedVerifyRequests";
import RequestDetails from "./pages/Dashboard/AdminDashboard/ManageVerifyRequests/RequestDetails";
import SingleApplications from "./pages/Dashboard/AdminDashboard/SingleApplications/SingleApplications";
import UserDetails from "./pages/Dashboard/AdminDashboard/UserDetails/UserDetails";
import UserSongs from "./pages/Dashboard/AdminDashboard/UserSongs/UserSongs";
import Users from "./pages/Dashboard/AdminDashboard/Users/Users";
import Dashboard from "./pages/Dashboard/Dashboard";
import Index from "./pages/Dashboard/Index";
import Profile from "./pages/Dashboard/Profile/Profile";
import ChangePassword from "./pages/Dashboard/Setting/ChangePassword";
import Setting from "./pages/Dashboard/Setting/Setting";
import Agreement from "./pages/Dashboard/UserDashboard/Agreement/Agreement";
import AllClaimReleases from "./pages/Dashboard/UserDashboard/ClaimRelease/AllClaimReleases";
import ClaimRelease from "./pages/Dashboard/UserDashboard/ClaimRelease/ClaimRelease";
import NewClaimRelease from "./pages/Dashboard/UserDashboard/ClaimRelease/NewClaimRelease";
import NewRelease from "./pages/Dashboard/UserDashboard/NewRelease/NewRelease";
import ApprovedReleases from "./pages/Dashboard/UserDashboard/Releases/ApprovedReleases";
import PendingReleases from "./pages/Dashboard/UserDashboard/Releases/PendingReleases";
import RejectedReleases from "./pages/Dashboard/UserDashboard/Releases/RejectedReleases";
import Releases from "./pages/Dashboard/UserDashboard/Releases/Releases";
import TakedownReleases from "./pages/Dashboard/UserDashboard/Releases/TakedownReleases";
import SingleRelease from "./pages/Dashboard/UserDashboard/SingleRelease/SingleRelease";
import SpotifyVerifyRequest from "./pages/Dashboard/UserDashboard/SpotifyVerifyRequest/SpotifyVerifyRequest";
import Wallet from "./pages/Dashboard/UserDashboard/Wallet/Wallet";
import YtOACRequest from "./pages/Dashboard/UserDashboard/YtOACRequest/YtOACRequest";
import { setAppOptions } from "./redux/features/app/AppSlice";
import { useAppDispatch } from "./redux/store";
import NotFound from "./shared/NotFound";

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data, isLoading, refetch } = useQuery("appName", async () => {
    const res = await axios.get(`${BASE_API}/app/get-app-name`);
    return res?.data?.data;
  });

  useEffect(() => {
    if (isLoading) return;
    if (data) {
      dispatch(setAppOptions(data?.name));
      refetch();
    }
  }, [data, dispatch, isLoading, refetch]);

  return (
    <>
      <Suspense fallback={<HomeLoader />}>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          >
            <Route index element={<Index />} />
            <Route
              path="applications"
              element={
                <RequireAdmin>
                  <Applications />
                </RequireAdmin>
              }
            >
              <Route index element={<PendingApplications />} />
              <Route
                path="pending-applications"
                element={<PendingApplications />}
              />
              <Route
                path="approved-applications"
                element={<ApprovedApplications />}
              />
              <Route
                path="rejected-applications"
                element={<RejectedApplications />}
              />
            </Route>
            <Route
              path="applications/:id"
              element={
                <RequireAdmin>
                  <SingleApplications />
                </RequireAdmin>
              }
            />
            <Route
              path="all-artists"
              element={
                <RequireAdmin>
                  <Users />
                </RequireAdmin>
              }
            />
            <Route
              path="all-artists/:id"
              element={
                <RequireAdmin>
                  <UserDetails refetch={refetch} />
                </RequireAdmin>
              }
            />
            <Route
              path="all-artists/:id/songs"
              element={
                <RequireAdmin>
                  <UserSongs />
                </RequireAdmin>
              }
            />
            <Route
              path="all-artists/songs/:id"
              element={
                <RequireAdmin>
                  <SingleRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="all-admins"
              element={
                <RequireAdmin>
                  <Admins />
                </RequireAdmin>
              }
            />

            <Route
              path="releases"
              element={
                <RequireAuth>
                  <Releases />
                </RequireAuth>
              }
            >
              <Route index element={<PendingReleases />} />
              <Route path="pending-releases" element={<PendingReleases />} />
              <Route path="approved-releases" element={<ApprovedReleases />} />
              <Route path="rejected-releases" element={<RejectedReleases />} />
              <Route path="takedown-releases" element={<TakedownReleases />} />
            </Route>

            <Route
              path="releases/:id"
              element={
                <RequireAuth>
                  <SingleRelease />
                </RequireAuth>
              }
            />
            <Route
              path="new-release"
              element={
                <RequireAuth>
                  <NewRelease />
                </RequireAuth>
              }
            />
            <Route
              path="agreement"
              element={
                <RequireAuth>
                  <Agreement />
                </RequireAuth>
              }
            />
            <Route
              path="all-releases"
              element={
                <RequireAdmin>
                  <ManageAllReleases />
                </RequireAdmin>
              }
            >
              <Route index element={<PendingAllReleases />} />
              <Route
                path="pending-all-releases"
                element={<PendingAllReleases />}
              />
              <Route
                path="approved-all-releases"
                element={<ApprovedAllReleases />}
              />
              <Route
                path="rejected-all-releases"
                element={<RejectedAllReleases />}
              />
              <Route
                path="takedown-all-releases"
                element={<TakedownAllReleases />}
              />
            </Route>
            <Route
              path="pending-all-releases/:id"
              element={
                <RequireAdmin>
                  <SingleRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="approved-all-releases/:id"
              element={
                <RequireAdmin>
                  <SingleRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="approved-all-releases/:id/edit"
              element={
                <RequireAdmin>
                  <EditRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="rejected-all-releases/:id"
              element={
                <RequireAdmin>
                  <SingleRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="takedown-all-releases/:id"
              element={
                <RequireAdmin>
                  <SingleRelease />
                </RequireAdmin>
              }
            />
            <Route
              path="setting"
              element={
                <RequireAuth>
                  <Setting />
                </RequireAuth>
              }
            >
              <Route index element={<ChangePassword />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="yt-oac-request" element={<YtOACRequest />} />
              <Route
                path="spotify-verify-request"
                element={<SpotifyVerifyRequest />}
              />
            </Route>
            <Route
              path="all-verify-requests"
              element={
                <RequireAdmin>
                  <ManageVerifyRequests />
                </RequireAdmin>
              }
            >
              <Route index element={<PendingVerifyRequests />} />
              <Route
                path="pending-requests"
                element={<PendingVerifyRequests />}
              />
              <Route path="pending-requests/:id" element={<RequestDetails />} />
              <Route
                path="approved-requests"
                element={<ApprovedVerifyRequests />}
              />
              <Route
                path="approved-requests/:id"
                element={<RequestDetails />}
              />
              <Route
                path="rejected-requests"
                element={<RejectedVerifyRequests />}
              />
              <Route
                path="rejected-requests/:id"
                element={<RequestDetails />}
              />
            </Route>
            <Route
              path="wallet"
              element={
                <RequireAuth>
                  <Wallet refetch={refetch} />
                </RequireAuth>
              }
            />
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="claim-requests"
              element={
                <RequireAuth>
                  <ClaimRequests />
                </RequireAuth>
              }
            />
            <Route
              path="claim-release"
              element={
                <RequireAuth>
                  <ClaimRelease />
                </RequireAuth>
              }
            >
              <Route index element={<AllClaimReleases />} />
              <Route path="all" element={<AllClaimReleases />} />
              <Route path="new" element={<NewClaimRelease />} />
            </Route>
          </Route>
          <Route
            path="/login"
            element={
              <AuthChangeRoute>
                <Login />
              </AuthChangeRoute>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/new-password/:verified"
            element={<ResetPasswordField />}
          />
          <Route
            path="/update-password/:verified"
            element={<PasswordChangeField />}
          />
          <Route path="/verify-mail" element={<VerifyMail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
