import React from "react";

type TitleProps = {
  title: string;
  subtitle: string;
};

export default function Title({ title, subtitle }: TitleProps) {
  return (
    <div className="title my-2 mb-5">
      <h3 className="text-2xl font-bold">{title}</h3>
      <span>{subtitle}</span>
    </div>
  );
}
