import React from "react";
import { Link } from "react-router-dom";
import Image from "../assets/404.jpg";
import { HiArrowLongLeft } from "react-icons/hi2";
import useTitle from "../hooks/useTitle";

export default function NotFound() {
  useTitle("404 Not Found");
  return (
    <div className="flex flex-col justify-center items-center h-screen gap-5">
      <img
        src={Image}
        alt="404"
        className="w-full h-auto object-cover max-w-[500px]"
      />
      <Link
        to={"/"}
        className="flex items-center gap-2 bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white px-3 py-2 rounded-md"
      >
        <HiArrowLongLeft size={25} />
        Go Back
      </Link>
    </div>
  );
}
