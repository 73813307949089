import React from "react";

type Props = {
  title: string;
};

export default function MiniTitle({ title }: Props) {
  return (
    <div className="title my-2 mb-5">
      <h3 className="text-xl font-bold flex items-center gap-2">{title}</h3>
    </div>
  );
}
