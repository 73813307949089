import React, { useState } from "react";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";

type Props = {
  url: string;
  title: string;
};

export default function DownloadAudio({ url, title }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleDownload = () => {
    setIsLoading(true);
    const fileTitle = url.split("/").pop() as string;
    const getExtension = fileTitle.split(".").pop() as string;
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const progress =
          Math.round(
            (progressEvent.loaded / (progressEvent.total as any)) * 100,
          ) || 0;
        setProgress(progress);
        if (progress === 100) {
          setTimeout(() => {
            setProgress(0);
          }, 1000);
        }
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.${getExtension}`);
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    });
  };
  return (
    <div className="w-[80%] md:w-1/2 lg:w-1/3 mx-auto mt-10">
      {isLoading && progress > 0 ? (
        <ProgressBar completed={progress} width="100%" height="25px" />
      ) : (
        <button
          className="btn btn-sm md:btn-md border-none bg-gradient-to-l from-gradientSecondary to-gradientPrimary text-white flex mx-auto"
          onClick={handleDownload}
        >
          Download
        </button>
      )}
    </div>
  );
}
