import { Link } from "react-router-dom";
import Avatar from "../../../../assets/avatar.jpg";
import Spotify from "../../../../assets/spotify.jpg";
import YT from "../../../../assets/yt.jpg";
import GlobalLoader from "../../../../components/GlobalLoader";
import MiniTitle from "../../../../components/MiniTitle";
import useTitle from "../../../../hooks/useTitle";
import { useGetAllVerifyRequestQuery } from "../../../../redux/features/VerifyRequestApi";

const Fade = require("react-reveal/Fade");

export default function PendingVerifyRequests() {
  useTitle("Pending Verify Requests");

  const { data, isLoading } = useGetAllVerifyRequestQuery({
    status: "pending",
  });

  const requests = data?.data;

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Fade top distance="20px">
      <div className="py-0 md:pb-5 lg:py-5 rounded-md">
        <MiniTitle title="Pending Verify Requests" />

        {requests?.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {requests?.map((request: any) => (
              <Link
                to={`/all-verify-requests/pending-requests/${request._id}`}
                key={request._id}
                className={`relative w-full rounded-xl shadow-md flex flex-col group`}
              >
                <div className="w-full overflow-hidden rounded-xl glass">
                  <img
                    className="w-full h-32 md:h-48 lg:h-52 object-cover md:group-hover:scale-110 duration-300"
                    src={
                      request?.type === "youtube-oac"
                        ? YT
                        : request?.type === "spotify-artist"
                          ? Spotify
                          : Avatar
                    }
                    alt={request?.userEmail}
                    loading="lazy"
                  />
                </div>
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#ffffff00] to-black opacity-100 transition-opacity duration-300 ease-in-out rounded-xl"></div>
                <div className="absolute bottom-0 left-0 p-4 text-white opacity-100 transition-opacity duration-300 ease-in-out rounded-xl">
                  <h3 className="text-base md:text-lg font-bold md:hidden">
                    {request?.userFullName?.length > 15
                      ? request?.userFullName?.slice(0, 15) + "..."
                      : request?.userFullName}
                  </h3>
                  <h3 className="text-base md:text-lg font-bold hidden md:block">
                    {request?.userFullName}
                  </h3>
                  <p className="text-xs md:text-sm font-semibold md:hidden">
                    {request?.userEmail?.length > 22
                      ? request?.userEmail?.slice(0, 22) + "..."
                      : request?.userEmail}
                  </p>
                  <p className="text-xs md:text-sm font-semibold hidden md:block">
                    {request?.userEmail}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="card flex flex-col justify-center items-center mx-auto w-full md:w-[300px] mt-24">
            <div className="card-body">
              <h1 className="text-center text-sm text-red-600">
                Oops! No pending requests found!
              </h1>
            </div>
          </div>
        )}
      </div>
    </Fade>
  );
}
