import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiArrowLongLeft } from "react-icons/hi2";
import useTitle from "../../../../hooks/useTitle";

const Fade = require("react-reveal/Fade");

export default function RequestDetails() {
  useTitle("Request details");

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  return (
    <Fade top distance="20px">
      <div className="py-0 md:pb-5 lg:py-5 rounded-md">
        <div className="title my-2 mb-5">
          <h3 className="text-xl font-bold flex items-center gap-2">
            <HiArrowLongLeft
              onClick={() => navigate(-1)}
              size={25}
              className="cursor-pointer"
            />
            {id}
          </h3>
        </div>
      </div>
    </Fade>
  );
}
