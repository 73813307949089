import { useState } from "react";
import { BsImage } from "react-icons/bs";
import { FaRegLightbulb } from "react-icons/fa";
import { uploadFile } from "./upload-file";

interface UploadAlbumCoverProps {
  setValue: any;
  albumCoverView: string;
  setAlbumCoverView: (value: string) => void;
}

export default function UploadAlbumCover({
  setValue,
  albumCoverView,
  setAlbumCoverView,
}: UploadAlbumCoverProps) {
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = (e: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = e.target?.result as string;

        img.onload = async () => {
          if (img.width === 3000 && img.height === 3000) {
            setIsImageError(false);
            try {
              const result = await uploadFile(selectedFile, setUploadProgress);
              if (result && result.url) {
                setAlbumCoverView(result.url);
                setValue("albumCover", result.url);
                setUploadProgress(0);
              } else {
                throw new Error(
                  "File upload failed or returned an invalid response.",
                );
              }
            } catch (error) {
              console.error("Error during file upload:", error);
              setIsImageError(true);
            }
          } else {
            setIsImageError(true);
          }
        };

        img.onerror = () => {
          setIsImageError(true);
        };
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setIsImageError(true);
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center gap-5 pb-3 sm:pb-0 lg:pb-3">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <label
          htmlFor="choose-album-cover"
          className={`input-group flex items-center overflow-hidden border-2 ${!albumCoverView && "py-24"} border-dotted rounded-md mt-2 justify-center cursor-grabbing`}
        >
          {albumCoverView ? (
            <div className="w-full h-60 md:h-72 overflow-hidden mx-auto rounded-md">
              <img
                src={albumCoverView}
                alt="previewImage"
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          ) : isImageError ? (
            <div className="absolute inset-0 flex items-center justify-center bg-error bg-opacity-70 rounded-md">
              <p className="font-bold text-3xl text-center px-3 md:px-0">
                Image size must be 3000x3000
              </p>
            </div>
          ) : uploadProgress > 0 ? (
            <div className="w-[70%] h-full flex flex-col items-center justify-center gap-5">
              <div
                style={{ width: `${uploadProgress}%` }}
                className={`h-2 bg-primary rounded-md`}
              />
              <p className="text-primary text-lg font-bold ml-2">
                {uploadProgress}% uploaded
              </p>
            </div>
          ) : (
            <div className="icon text-5xl">
              <BsImage />
            </div>
          )}
        </label>
        <input
          type="file"
          accept=".jpg"
          className="form-control outline-none pl-4 w-full hidden"
          id="choose-album-cover"
          onChange={handleFileChange}
        />
      </div>
      <div className="w-full md:w-1/2 lg:w-2/3 md:flex flex-col hidden">
        <p className="flex items-start gap-3">
          <span className="mt-1 text-warning">
            <FaRegLightbulb />
          </span>
          Recommended images are 3000x3000 square JPG format. This is just a
          recommendation though—we accept most image sizes.
        </p>
        <p className="flex items-start gap-3">
          <span className="mt-1 text-warning">
            <FaRegLightbulb />
          </span>
          Stores will reject artwork that contains a website address (URL),
          Twitter name, or any image that's pixelated, rotated, or poor quality.
          They'll also reject artwork with prices or store logos (don't put an
          iTunes or Spotify logo on your artwork). Also, please don't reuse the
          same artwork for multiple albums.
        </p>
        <p className="flex items-start gap-3">
          <span className="mt-1 text-warning">
            <FaRegLightbulb />
          </span>
          You own this artwork and everything in it. Stores will reject your
          artwork if it contains images you found online that you don't have the
          explicit right to use.
        </p>
      </div>
    </div>
  );
}
