import { FaRegLightbulb } from "react-icons/fa";

export default function RemixVersionGuideline() {
  return (
    <div>
      <input
        type="checkbox"
        id="remix-version-guideline"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <div className="modal-body">
            <div className="w-full">
              <p className="flex items-start gap-3">
                <span className="mt-1 text-warning">
                  <FaRegLightbulb />
                </span>
                Only use this field if this release is a different version from
                the original recording. <br /> <br /> For example: <br />{" "}
                Re-recorded Version, <br /> Remastered Version, <br /> 2010
                Version etc.
              </p>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="remix-version-guideline">
          Close
        </label>
      </div>
    </div>
  );
}
