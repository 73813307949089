import axios from "axios";
import {
  CLOUD_NAME,
  FOLDER,
  UPLOAD_PRESET,
  UPLOAD_URL,
} from "../configs/config";

export const uploadFile = async (
  file: File,
  setProgress: (value: number) => void,
) => {
  setProgress(0);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", UPLOAD_PRESET);
  formData.append("folder", FOLDER);
  formData.append("cloud_name", CLOUD_NAME);

  try {
    const res = await axios.post(UPLOAD_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          progressEvent.total
            ? (progressEvent.loaded * 100) / progressEvent.total
            : 0,
        );
        setProgress(percentCompleted);
      },
    });

    return {
      url: res.data.secure_url,
      publicId: res.data.public_id,
    };
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
