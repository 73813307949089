import React from "react";
import useTitle from "../../../../hooks/useTitle";
import { Outlet, useNavigate } from "react-router-dom";

export default function Releases() {
  useTitle("All Releases");

  const navigate = useNavigate();

  let tabs = [
    {
      id: 1,
      title: "Pending",
      link: "pending-releases",
    },
    {
      id: 2,
      title: "Approved",
      link: "approved-releases",
    },
    {
      id: 3,
      title: "Rejected",
      link: "rejected-releases",
    },
    {
      id: 4,
      title: "Takedown",
      link: "takedown-releases",
    },
  ];

  return (
    <div className="lg:px-5 py-0 md:pb-5 lg:py-5">
      <div className="title my-2 mb-5">
        <h3 className="text-2xl font-bold">All releases list</h3>
        <span>You can view all your releases here with their status</span>
      </div>
      <div className="tabs tabs-boxed mt-5 gap-2 bg-transparent duration-300">
        {tabs.map((tab, i) => (
          <span
            key={i}
            className={`tab md:tab-lg border 
                                                  ${tab.link === window.location.pathname.split("/")[2] ? "tab-active" : ""}
                                                  ${window.location.pathname === "/releases" && tab.title === "Pending" ? "tab-active" : ""}
                                                  `}
            onClick={() => navigate(`/releases/${tab.link}`)}
          >
            {tab.title}
          </span>
        ))}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
