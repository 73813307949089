import { format } from "date-fns";
import formatDistance from "date-fns/formatDistance";
import { BiCamera } from "react-icons/bi";
import { TbEdit } from "react-icons/tb";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { MdVerified } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useAuth from "../../../hooks/useAuth";
import useTitle from "../../../hooks/useTitle";
import { authUserInterface } from "../../../interfaces/UserInterface";
import ImageChangeModal from "./ImageChangeModal";
import ProfileModal from "./ProfileModal";
import GlobalLoader from "../../../components/GlobalLoader";

const Profile = () => {
  const {
    user,
    updatedUser: data,
    refetch,
    isLoading,
  } = useAuth<authUserInterface | any>({});

  const name = data?.firstName + " " + data?.lastName;

  useTitle(name + "'s Profile");

  const dateDistance = formatDistance(
    new Date(),
    new Date(user?.user?.createdAt),
    {
      addSuffix: true,
    },
  );

  if (isLoading) {
    return <GlobalLoader />;
  }

  const result = format(new Date(user?.user?.updatedAt), "PPPP BBBB ppp");

  return (
    <>
      <div className="profile p-5 my-5 bg-white">
        <div className="flex items-center gap-2">
          <h2 className="text-2xl font-bold">
            <span className="text-gradientPrimary">
              {data?.firstName || "No Available"}'s
            </span>{" "}
            Profile{" "}
          </h2>
          {data?.role === "admin" && (
            <small className="badge badge-error select-none text-white uppercase font-bold">
              Admin
            </small>
          )}
        </div>
        <div className="profile-content py-10">
          <div className="flex items-center justify-between">
            <div className="profile-image rounded-full w-32 h-32 relative">
              <LazyLoadImage
                src={data?.profileImage ? data?.profileImage : data?.avatar}
                alt={data?.name}
                effect="black-and-white"
                className="w-32 h-32 rounded-full border-4 border-gradientPrimary object-cover shadow-lg"
              />

              <label
                htmlFor="profile-image-edit-modal"
                className="profile-image-edit absolute right-0 bottom-5 text-lg cursor-pointer w-8 h-8 rounded-full grid place-items-center shadow bg-gradientPrimary text-white "
              >
                <BiCamera />
              </label>
            </div>
            <div className="social-links">
              <ul className="flex justify-center items-center gap-5 py-5">
                {data?.fbLink && (
                  <li className="tooltip" data-tip="Facebook">
                    <a
                      href={data?.fbLink}
                      target="_blank"
                      className="btn-sm text-lg btn-circle btn-ghost btn btn-outline"
                      rel="noreferrer"
                    >
                      <BsFacebook />
                    </a>
                  </li>
                )}
                {data?.ytLink && (
                  <li className="tooltip" data-tip="YouTube">
                    <a
                      href={data?.ytLink}
                      target="_blank"
                      className="btn-sm text-lg btn-circle btn-ghost btn btn-outline"
                      rel="noreferrer"
                    >
                      <BsYoutube />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="profile-details py-5 font-poppins">
            {data?.role !== "admin" && (
              <div className="flex justify-end">
                <label
                  htmlFor="profile-edit-modal"
                  className="edit-btn cursor-pointer md:my-4"
                >
                  <div className="tooltip" data-tip="Edit Profile">
                    <TbEdit size={22} />
                  </div>
                </label>
              </div>
            )}
            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
              <span className="profile-details-item-label">Name</span>
              <span className="profile-details-item-value font-bold select-none flex items-center gap-2">
                {data?.firstName} {data?.lastName}{" "}
                {data?.role === "admin" && (
                  <span
                    className="tooltip tooltip-right md:tooltip-left"
                    data-tip="Mirror-LTD Music confirmed this profile is authentic."
                    title="Verified account"
                  >
                    <MdVerified color="#1C96E8" />
                  </span>
                )}
              </span>
            </div>
            {data?.role !== "admin" && (
              <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                <span className="profile-details-item-label">Artist Bio</span>
                <span className="profile-details-item-value font-bold select-none flex items-center gap-2">
                  {data?.artistBio?.length > 50
                    ? data?.artistBio?.slice(0, 50) + "..."
                    : data?.artistBio}
                </span>
              </div>
            )}
            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
              <span className="profile-details-item-label">Email</span>
              <span className="profile-details-item-value font-bold select-none">
                {data?.email || "No Available"}
              </span>
            </div>
            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
              <span className="profile-details-item-label">Phone</span>
              <span className="profile-details-item-value font-bold">
                {data?.phoneNumber || "No Available"}
              </span>
            </div>
            {data?.role !== "admin" && (
              <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                <span className="profile-details-item-label">Address</span>
                {data?.address ? (
                  <span className="profile-details-item-value font-bold">
                    {data?.address}, {data?.zipCode}, {data?.city},{" "}
                    {data?.country}
                  </span>
                ) : (
                  <span className="profile-details-item-value font-bold"></span>
                )}
              </div>
            )}
            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
              <span className="profile-details-item-label">Joined Date</span>
              <span className="profile-details-item-value font-bold">
                {dateDistance}
              </span>
            </div>
            {data?.role === "admin" && (
              <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                <span className="profile-details-item-label">Role</span>
                <span className="profile-details-item-value font-bold">
                  <span className="badge badge-outline">Admin</span>
                </span>
              </div>
            )}
            {/* <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
                                                                                <span className="profile-details-item-label">Status</span>
                                                                                <span className="profile-details-item-value font-bold">
                                                                                          <span className="badge badge-info text-white">Active</span>
                                                                                </span>
                                                                      </div> */}
            <div className="profile-details-item flex sm:items-center flex-col sm:flex-row justify-between text-lg mb-2 border-b pb-2">
              <span className="profile-details-item-label">Last Login</span>
              <span className="profile-details-item-value font-bold">
                <span className="badge badge-ghost whitespace-pre-wrap">
                  {result}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal refetch={refetch} />
      <ImageChangeModal refetch={refetch} />
    </>
  );
};

export default Profile;
