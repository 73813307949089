import { useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BsMusicNote } from "react-icons/bs";
import { FaRegLightbulb } from "react-icons/fa";
import { uploadFile } from "./upload-file";

interface UploadAudioFileProps {
  song?: any;
  setValue: any;
  file: File | null;
  setFile: (value: File | null) => void;
}

export default function UploadAudioFile({
  song,
  setValue,
  file,
  setFile,
}: UploadAudioFileProps) {
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      try {
        const result = await uploadFile(selectedFile, setUploadProgress);
        if (result && result.url) {
          setFile(selectedFile);
          setValue("audioFile", result.url);
          setUploadProgress(0);
        } else {
          throw new Error(
            "File upload failed or returned an invalid response.",
          );
        }
      } catch (error) {
        console.error("Error during file upload:", error);
      }
    }
  };

  return (
    <div className="name border rounded p-3 relative mt-10 flex-1 w-full md:w-1/2 lg:w-1/3">
      <div className="name-title absolute -top-4 bg-white border rounded p-1">
        <h3 className="text-sm font-bold flex items-center gap-2">
          Upload your audio file (Only .mp3){" "}
          <span className="text-red-600">*</span>
          <div className="dropdown dropdown-top dropdown-end md:dropdown-hover md:dropdown-right">
            <label tabIndex={0} className="cursor-pointer">
              <AiOutlineQuestionCircle size={16} />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu px-3 py-6 shadow bg-base-100 border-2 rounded-box w-60"
            >
              <div className="w-full">
                <p className="flex items-start gap-3">
                  <span className="mt-1 text-warning">
                    <FaRegLightbulb />
                  </span>
                  Track title and release title must be same.
                </p>
              </div>
            </ul>
          </div>
        </h3>
      </div>
      <label
        htmlFor={`choose-audio-file`}
        className={`input-group flex items-center my-2 border-2 border-dotted ${file ? "py-20" : "py-24"} rounded-md mt-2 justify-center cursor-grabbing`}
      >
        {file ? (
          <div className="w-full mx-auto border-success">
            <h2 className="flex flex-col gap-3 justify-center items-center text-center h-full">
              <div className="flex items-center gap-2">
                <BsMusicNote />
                {file?.name?.length > 25 ? (
                  <p className="text-gray-500">{file?.name?.slice(0, 25)}...</p>
                ) : (
                  <p className="text-gray-500">{file?.name}</p>
                )}
              </div>
              <span className="badge badge-success text-white py-4 uppercase">
                Audio uploaded..!
              </span>
            </h2>
          </div>
        ) : uploadProgress > 0 ? (
          <div className="w-[70%] h-full flex flex-col items-center justify-center gap-5">
            <div
              style={{ width: `${uploadProgress}%` }}
              className={`h-2 bg-primary rounded-md`}
            />
            <p className="text-primary text-lg font-bold ml-2">
              {uploadProgress}% uploaded
            </p>
          </div>
        ) : (
          <div className="icon text-5xl">
            <BsMusicNote />
          </div>
        )}
      </label>
      <input
        type="file"
        accept=".mp3"
        className="form-control outline-none pl-4 w-full hidden"
        id={`choose-audio-file`}
        onChange={handleFileChange}
      />
      {song?.audioFile && (
        <audio src={song?.audioFile} controls className="w-full h-10 mt-2" />
      )}
    </div>
  );
}
