import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import { authUserInterface } from "../../interfaces/UserInterface";
import { useResetPasswordMutation } from "../../redux/features/auth/AuthApi";

export default function ResetPassword() {
  useTitle("Reset Password");
  const { user } = useAuth<authUserInterface | any>({});
  const [resetPassword, { data, isSuccess, isLoading, isError, error }] =
    useResetPasswordMutation();
  const [isSent, setIsSent] = useState<boolean>(false);
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();

  const handleResetPassword = handleSubmit(async (formData) => {
    if (!formData?.resetPasswordEmail) return toast.error("Email is required");
    await resetPassword({ email: formData?.resetPasswordEmail });
  });

  useEffect(() => {
    if (isError) {
      toast.error((error as any)?.data?.message);
    }

    if (isSuccess) {
      toast.success(data?.message);
      setIsSent(true);
      reset();
    }

    if (user?.isAuthenticated) {
      navigate("/");
    }
  }, [isSuccess, data, error, isError, reset, navigate, user]);

  return (
    <div className='bg-[url("./assets/bg-light.jpg")] bg-cover flex justify-center items-center h-screen'>
      <div className="card w-full md:w-2/3 lg:w-1/3 glass mx-3 md:mx-0">
        <div className="card-body">
          {isSent && (
            <div className="alert rounded-xl glass text-green-500 mb-3 flex justify-center mx-auto w-full md:w-1/2">
              Email successfully sent
            </div>
          )}
          <h2 className="card-title flex items-center gap-2">
            <HiOutlineArrowNarrowLeft
              size={25}
              className="cursor-pointer"
              onClick={() => navigate(-1)}
            />{" "}
            Reset Password
          </h2>
          <p>
            After click below this button. It will send you email with password
            reset link and this link will valid only for 1 hour. After 1 hour
            this link will not work anyway.
          </p>
          <form onSubmit={handleResetPassword} className="mt-4">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="email"
                placeholder="Enter your email"
                className="md:py-7 input input-bordered w-full bg-transparent text-black"
                {...register("resetPasswordEmail")}
              />
            </div>
            {isLoading ? (
              <button
                className="btn flex justify-center items-center mx-auto btn-error mt-6 text-white"
                disabled
              >
                <PulseLoader color="#fff" size={6} />
              </button>
            ) : (
              <button className="btn btn-sm md:btn-md flex justify-center items-center mx-auto bg-gradient-to-l from-gradientSecondary to-gradientPrimary mt-6 md:px-6 text-white">
                Reset
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
